import React from 'react';
import Link from 'components/Link';

function NavLink({
  to, name, children, ...other_props
}) {
  return (
    <Link to={to} {...other_props}>
      {children}
    </Link>
  );
}

export default NavLink;
