import React, { Component } from 'react';
import NextHead from 'next/head';
import { connect } from 'react-redux';
import { has } from 'lodash';
import { getFacility } from 'actions';

import { APP_CONFIG } from 'Config';

class PageHead extends Component {
    state = {
      facility_data: null,
      facility_description: '',
    };

    checkRender = (key, toRender, secondary_condition = true, default_value = null) => (secondary_condition && has(this.props, key)
            ? toRender
            : default_value);

    componentDidMount() {
      if (this.props.route_path.includes('facilities')) {
        this.props.getFacility(this.props.route_path.replace(/\D/g, ""));
        this.setState({ facility_description: `Storage unit auctions are happening now at a self storage facility in ${this.props.facility.data.facility.city}. Explore active auction details and photos, then start bidding & winning!` })
      }
    };

    render() {
        const {
            description = '',
            keywords = 'self storage auction, self storage auctions, storage auction, storage auctions, storage auctions usa, storage shed auctions, storage unit auction, storage unit auctions, storage units auction, storage auction information',

            title = '',
            og = {},
        } = this.props;

        const {
            image: og_image,
            description: og_description = description,
        } = og;

        return (
          <NextHead>
            <title>{title || 'StorageTreasures.com'}</title>
            <meta name="description" content={description || 'A free self storage auction directory offering real time auction listings, alerts, tools, how-to resources and more. The ultimate resource for storage auctions.'} />
            <meta name="keywords" content={keywords} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={og_description} />
            <meta property="og:type" content="article" />
            <meta property="og:image:type" content="image/jpeg" />

            {this.checkRender('og.image',
              <>
                <meta property="og:image" content={`${og_image}`} />
                <meta property="og:image_url" content={`${og_image}`} />
                <meta property="og:image:secure_url" content={`${og_image}`} />
              </>)}

            <meta property="og:image" content={`${APP_CONFIG.APP_URL}/static/images/logo_white_bg_social_media.jpg`} />
            <meta property="og:image_url" content={`${APP_CONFIG.APP_URL}/static/images/logo_white_bg_social_media.jpg`} />
            <meta property="og:image:secure_url" content={`${APP_CONFIG.APP_URL}/static/images/logo_white_bg_social_media.jpg`} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:alt" content={og_description} />
            <meta name="twitter:image:alt" content={title} />

            <meta name="loading" content="no" data-description="If an api call is being performed" />
            <meta name="loading_upload_percent" content="100%" data-description="File upload progress" />
          </NextHead>
        );
    }
}

const mapStateToProps = ({ page, facility }) => {
    const {
        title = '',
        description = '',
        og = {},
    } = page;

    return {
        title,
        description,
        og,
        facility
    };
};

const mapActionsToProps = { getFacility }

export default connect(mapStateToProps, mapActionsToProps)(PageHead);
