export const ADDRESS_COUNTRY = 'address_country';
export const ADDRESS_TIMEZONE = 'address_timezone';

export const GET_ADDRESS_COUNTRIES = 'get_address_states';
export const GET_ADDRESS_COUNTRIES_SUCCESS = 'get_address_states_success';
export const GET_ADDRESS_COUNTRIES_FAIL = 'get_address_states_fail';

export const SET_SELECTED_STATE = 'set_selected_state';
export const SET_STATES = 'set_states';

export const GET_ADDRESS_AUTOCOMPLETE = 'get_address_autocomplete';
export const GET_ADDRESS_AUTOCOMPLETE_SUCCESS = 'get_address_autocomplete_success';
export const GET_ADDRESS_AUTOCOMPLETE_FAIL = 'get_address_autocomplete_fail';
