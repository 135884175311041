export const CHECK_USER_DUPLICATE_EMAIL = 'check_user_duplicate_email'
export const CHECK_USER_DUPLICATE_PHONE = 'check_user_duplicate_phone'

export const GET_HEARD_QUESTIONS = 'get_heard_questions'
export const GET_SECURITY_QUESTIONS = 'get_security_questions'

export const CREATE_USER_NOTIFICATION = 'create_user_notification'
export const CREATE_USER_NOTIFICATION_SUCCESS = 'create_user_notification_success'
export const CREATE_USER_NOTIFICATION_FAIL = 'create_user_notification_fail'

export const GET_USER_NOTIFICATIONS = 'get_user_notifications'
export const GET_USER_NOTIFICATIONS_SUCCESS = 'get_user_notifications_success'
export const GET_USER_NOTIFICATIONS_FAIL = 'get_user_notifications_fail'

export const GET_USER_NOTIFICATION = 'get_user_notification'
export const GET_USER_NOTIFICATION_SUCCESS = 'get_user_notification_success'
export const GET_USER_NOTIFICATION_FAIL = 'get_user_notification_fail'

export const UPDATE_USER_NOTIFICATION = 'update_user_notification'
export const UPDATE_USER_NOTIFICATION_SUCCESS = 'update_user_notification_success'
export const UPDATE_USER_NOTIFICATION_FAIL = 'update_user_notification_fail'

export const DELETE_USER_NOTIFICATION = 'delete_user_notification'
export const DELETE_USER_NOTIFICATION_SUCCESS = 'delete_user_notification_success'
export const DELETE_USER_NOTIFICATION_FAIL = 'delete_user_notification_fail'
export const SETTINGS_INPUT_CHANGED = 'settings_input_changed'
export const SETTINGS_FORM_CLEAR = 'settings_form_clear'

export const USER_SETTINGS_GET = 'user_settings_get'
export const USER_SETTINGS_GET_SUCCESS = 'user_settings_get_success'
export const USER_SETTINGS_GET_FAIL = 'user_settings_get_fail'

export const USER_SETTINGS_SAVE = 'user_settings_save'
export const USER_SETTINGS_SAVE_SUCCESS = 'user_settings_save_success'
export const USER_SETTINGS_SAVE_FAIL = 'user_settings_save_fail'

export const USER_SETTINGS_PAYMENT_METHOD = 'user_settings_payment_method'

export const GET_WON_AUCTIONS = 'get_won_auctions'
export const GET_WON_AUCTIONS_SUCCESS = 'get_won_auctions_success'
export const GET_WON_AUCTIONS_FAIL = 'get_won_auctions_fail'

export const GET_USER_AUCTION_WATCH = 'get_user_auction_watch'
export const GET_USER_AUCTION_WATCH_SUCCESS = 'get_user_auction_watch_success'
export const GET_USER_AUCTION_WATCH_FAIL = 'get_user_auction_watch_fail'

export const REMOVE_USER_WATCHED_AUTION = 'get_user_watched_auction'
export const REMOVE_USER_WATCHED_AUTION_SUCCESS = 'get_user_watched_auction_success'
export const REMOVE_USER_WATCHED_AUTION_FAIL = 'get_user_watched_auction_faile'

export const GET_USER_BOOKMARKED_FACILITIES = 'get_user_bookmarked_facilities'
export const GET_USER_BOOKMARKED_FACILITIES_SUCCESS = 'get_user_bookmarked_facilities_success'
export const GET_USER_BOOKMARKED_FACILITIES_FAIL = 'get_user_bookmarked_facilities_fail'

export const DELETE_USER_BOOKMARKED_FACILITY = 'delete_user_bookmarked_facility'
export const DELETE_USER_BOOKMARKED_FACILITY_SUCCESS = 'delete_user_bookmarked_facility_success'
export const DELETE_USER_BOOKMARKED_FACILITY_FAIL = 'delete_user_bookmarked_facility_fail'

export const GET_USER_AUCTIONS_WITH_BIDS = 'get_user_auctions_with_bids'
export const GET_USER_AUCTIONS_WITH_BIDS_SUCCESS = 'get_user_auctions_with_bids_success'
export const GET_USER_AUCTIONS_WITH_BIDS_FAIL = 'get_user_auctions_with_bids_fail'

export const FORGOT_PASSWORD = 'forgot_password'
export const FORGOT_PASSWORD_SUCCESS = 'forgot_password_success'
export const FORGOT_PASSWORD_FAIL = 'forgot_password_fail'

export const FORGOT_PASSWORD_CHANGE = 'forgot_password_change'
export const FORGOT_PASSWORD_CHANGE_SUCCESS = 'forgot_password_change_success'
export const FORGOT_PASSWORD_CHANGE_FAIL = 'forgot_password_change_fail'

export const SET_FORMATTED_TABLE_DATA = 'set_formatted_table_data'

export const NOTIFICATIONS_FORM_CLEAR = 'notifications_form_clear'
export const NOTIFICATIONS_INPUT_CHANGED = 'notifications_input_changed'

export const CHECK_PRO_STATUS = 'check_pro_status'

export const UPGRADE_INPUT_FORM_CHANGE = 'upgrade_input_form_change'
export const UPGRADE_FORM_CLEAR = 'upgrade_form_clear'


export const CHANGE_MEMBERSHIP = 'change_membership'
export const CHANGE_MEMBERSHIP_SUCCESS = 'change_membership_success'
export const CHANGE_MEMBERSHIP_FAIL = 'change_membership_fail'
export const AUCTION_BID_SUBMIT='auction_bid_submit'
export const AUCTION_BID_SUBMIT_SUCCESS='auction_bid_submit_success'
export const AUCTION_BID_SUBMIT_FAIL='auction_bid_submit_fail'

export const USER_BID_BANNER='user_bid_banner'

export const USER_CHECKING_PROMO_CODE = 'user_checking_promo_code'
export const USER_CHECKING_PROMO_CODE_SUCCESS = 'user_checking_promo_code_success'
export const USER_CHECKING_PROMO_CODE_FAIL = 'user_checking_promo_code_fail'

export const USER_LOGOUT = 'user_logout'
export const USER_LOGIN = 'user_login'

export const USER_PERSIST_LOGIN = 'user_persist_login'

export const GET_USER_PERMISSIONS = 'get_user_permissions'
export const GET_USER_PERMISSIONS_SUCCESS = 'get_user_permissions_success'
export const GET_USER_PERMISSIONS_FAIL = 'get_user_permissions_fail'
export const GET_ID_VERIFICATION_QUESTIONS = 'get_id_verification_questions'
export const GET_ID_VERIFICATION_QUESTIONS_SUCCESS = 'get_id_verification_questions_success'
export const GET_ID_VERIFICATION_QUESTIONS_FAIL = 'get_id_verification_questions_fail'

export const GET_USER_PICKUP_PERSONS = 'get_user_pickup_persons'
export const GET_USER_PICKUP_PERSONS_SUCCESS = 'get_user_pickup_persons_success'
export const GET_USER_PICKUP_PERSONS_FAIL = 'get_user_pickup_persons_fail'