import {
    GET_OFFER,
    GET_OFFER_FAIL,
    GET_OFFER_SUCCESS,
    UPDATE_OFFER,
    UPDATE_OFFER_SUCCESS,
    UPDATE_OFFER_FAIL,
} from 'actions/OfferTypes';

const INITIAL_STATE = {
    offer: {},
    myoffers: [],
    loaded: false,
    loading_offer: true,
    error: null,
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_OFFER:
            return {...state, loading_offer: true, loaded: false, error: null};
        case GET_OFFER_SUCCESS:
            return { ...state, loading_offer: false, loaded: true, offer: action.payload.offer, error: null };
        case GET_OFFER_FAIL:
            return {...state, loading_offer: false, loaded: true, error: "Failed to retrieve the offer details"};
        case UPDATE_OFFER:
            return {...state, loading_offer: true, loaded:false, error: null};
        case UPDATE_OFFER_SUCCESS:
            return {...state, loading_offer: false, loaded: true, offer: action.payload, error: null};
        case UPDATE_OFFER_FAIL:
            return {...state, loading_offer: false, loaded: true, error: action.error};
        default:
            return state;
    }

}


