import { useEffect, useState } from 'react'
import {
    getUserPreferences,
    saveUserPreferences,
    setDashboardPage,
    setPage
} from 'actions'
import { connect } from 'react-redux'
import { AB } from 'Enums/middleware'
import Table from 'components/partials/Table'
import { formatResults } from 'components/AuthorizedPickupPerson/Table/format'
import Loading from 'components/Loading'
import headers from 'components/AuthorizedPickupPerson/Table/headers'
import useService from 'hooks/useService'
import AddPickupPerson from 'components/AuthorizedPickupPerson/Modals/AddPickupPerson'
import ConfirmationModal from 'components/AuthorizedPickupPerson/Modals/Confirmation'
import { Row, Col } from 'react-bootstrap'
import Button from 'components/partials/Fields/Button'
import { POST, DELETE } from 'services/RequestMethods'
import { general_error_toastr, general_success_toastr } from 'components/partials/Notification'
import MissingBlock from 'components/partials/MissingBlock'
import { APP_CONFIG } from 'Config'
import { useGlobalContext } from 'context/GlobalContext'

const AuthorizedPickupPerson = ({
    loading: propLoading,
    canRespond = true,
    add = true,
    removePickupPersonCallback,
    ...props
}) => {
    const { user } = useGlobalContext()
    const userId = props.userId || props.loginUser
    const [email, setEmail] = useState('')
    const [showAddPickupPerson, setShowAddPickupPerson] = useState(false)
    const [showConfirmInvite, setShowConfirmInvite] = useState(false)
    const [showRemovePickupPerson, setShowRemovePickupPerson] = useState(false)
    const [pickupPersonId, setPickupPersonId] = useState(null)
    const [disableAddPickup, setDisableAddPickup] = useState(true)
    const { REACT_APP_ADD_AUTHORIZED } = APP_CONFIG

    const [
        { data: { num_results = 0, results = [] } = {}, loading },
        fireGetAuthorizedPickupPersons
    ] = useService({
        url: `users/${userId}/authorized-invites`,
        formatResponseCallback: results => formatResults(results, { setShowRemovePickupPerson, setPickupPersonId } )
    })

    const [{ data: addPickupResponse, loading: addingPickup }, fireAddPickup] = useService({
        url: `users/${userId}/authorized-invites`,
        method: POST
    })

    const [{ data: deletePickupResponse, loading: deletingPickup }, fireDeletePickup] = useService({
        method: DELETE
    })

    const handleAddPickupPersonConfirm = () => {
        setShowAddPickupPerson(false)
        setShowConfirmInvite(true)
    }

    const handleConfirmInvite = async () => {
        try {
            const response = await fireAddPickup({
                params: {
                    email: email
                }
            })

            if (response.message === 'OK') {
                general_success_toastr({
                    message: 'Authorized Pickup Person - invite sent'
                })
                handleRedoSearch()
                setEmail('')
                setShowConfirmInvite(false)
            } else {
                general_error_toastr({
                    message: 'Failed to send invite'
                })
                setShowConfirmInvite(false)
            }
        } catch (error) {
            console.error('API call failed', error)
            general_error_toastr({
                message: 'Failed to send invite'
            })
            setShowConfirmInvite(false)
        }
    }

    const handleConfirmRemovePickup = async () => {
        try {
            const response = await fireDeletePickup({
                url: `users/${userId}/authorized-invites/${pickupPersonId}`
            })

            if (response.message === 'OK') {
                general_success_toastr({
                    message: 'Authorized Pickup Person - removed'
                })
                handleRedoSearch()
                setShowRemovePickupPerson(false)
                removePickupPersonCallback && removePickupPersonCallback()
            } else {
                general_error_toastr('Failed to remove Authorized Pickup Person')
                setShowRemovePickupPerson(false)
            }
        } catch (error) {
            console.error('API call failed', error)
            general_error_toastr({
                message: 'Failed to remove Authorized Pickup Person'
            })
            setShowRemovePickupPerson(false)
        }
    }

    const handleRedoSearch = () => {
        fireGetAuthorizedPickupPersons()
    }

    useEffect(() => {
        fireGetAuthorizedPickupPersons()
    }, [])

    useEffect(() => {
        const membershipType = user?.membership?.type

        const membershipLimits = {
            'Basic Bidder': 0,
            'ProBidder': 1,
            'ProBidder Max': 3,
            'ProBusiness': 5
        }

        if (membershipType in membershipLimits) {
            setDisableAddPickup(results.length >= membershipLimits[membershipType])
        } else if (membershipType) {
            setDisableAddPickup(false)
        }
    }, [user?.membership?.type, results])

    return loading || propLoading ? <Loading /> : (
        <>
            {add &&
                <Row>
                    <Col className="text-right marginR15">
                        <Button
                            onClick={() => setShowAddPickupPerson(true)}
                            label="Add Authorized Pickup Person"
                            variant="primary"
                            disabled={disableAddPickup || !REACT_APP_ADD_AUTHORIZED}
                            tooltip={disableAddPickup && 'You have reached the limit of Authorized Pickup Person for your membership.'}
                        />
                    </Col>
                </Row>
            }

            <Table
                canRespond={canRespond}
                handleRedoSearch={handleRedoSearch}
                headers={headers}
                results={results}
                hasSort={false}
                hasPagination
                totalRecords={num_results}
                emptyRows={(<MissingBlock message="You have no Authorized Pickup Persons." />)}
            />
            <AddPickupPerson
                email={email}
                setEmail={setEmail}
                show={showAddPickupPerson}
                handleConfirm={handleAddPickupPersonConfirm}
                setShow={setShowAddPickupPerson}
            />
            <ConfirmationModal
                show={showConfirmInvite}
                setShow={setShowConfirmInvite}
                setEmail={setEmail}
                handleConfirm={handleConfirmInvite}
                loading={addingPickup}
            />
            <ConfirmationModal
                title="Confirm Removal"
                text="Are you sure you want to remove this Authorized Pickup Person?"
                submitLabel="Remove"
                show={showRemovePickupPerson}
                setShow={setShowRemovePickupPerson}
                loading={deletingPickup}
                setEmail={setEmail}
                handleConfirm={handleConfirmRemovePickup}
            />
        </>
    )
}

AuthorizedPickupPerson.middleware = AB

AuthorizedPickupPerson.getInitialProps = async ({ store: { dispatch }}) => {
    await Promise.all([
        dispatch(setPage({ title: 'My Account', marginBottom: 'pageTitle' })),
        dispatch(setDashboardPage({ title: 'Authorized Pickup Person Settings', menuEnum: 27 }))
    ])

    return {}
}

const mapStateToProps = ({ user }) => {
    const {
        user_id: userId
    } = user

    return {
        userId
    }
}

export default connect(mapStateToProps, {
    getUserPreferences,
    saveUserPreferences
})(AuthorizedPickupPerson)