import BaseService from "./BaseService";
import {environment} from "./environment";
import { DELETE, GET, POST } from './RequestMethods'

class ReportsService extends BaseService {
    getReports = (search_obj = {}) => (
        this.submitRequestWithPromise(GET, environment.apiEndPoint, `users/account/reports`, search_obj)
    );

    deleteReport = (report_id) => (
        this.submitRequestWithPromise(DELETE, environment.apiEndPoint, `users/account/reports/${report_id}`)
    )

    requestReports = (report_type, {status, term, ...search_obj}) => {
        search_obj.search_term = term || '';
        search_obj.report_type = report_type || '';
        search_obj.status = status || '';

        return this.submitRequestWithPromise(POST, environment.apiEndPoint, `users/account/reports`, search_obj);
    };
}

export default new ReportsService();
