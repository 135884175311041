import React, { Component } from 'react'
import Link from 'components/Link'
import { connect } from 'react-redux'
import { setPage } from 'actions'
import { get } from 'lodash'
import AuctionTile from './AuctionTile.jsx'
import 'styles/components/auction-tile.less'

class OtherAuctions extends Component {
    renderViewMore = () => {
        const facility_id = get(this.props, 'auctions.0.facility_id', 'missing')

        if (this.props.auctions && this.props.auctions.length > 6) {
            return (
                <div className="row text-center marginB15">
                    <Link to={`/facilities/${ facility_id}`} className="btn btn-regular btn-blue see-more">See More at This Facility</Link>
                </div>
            )
        }

        return null
    }

    render() {
        const {
            show = false,
            auction_loading,
            auction_error,
            navigatingToAuction
        } = this.props

        // NOTE :: Facility_loading is not here because it makes this area flash on the auction details page
        // when the api is called
        if (!show || auction_loading || auction_error || navigatingToAuction) {
            return null
        }

        if (this.props.auctions && this.props.auctions.length > 1) {
            return (
                <div className={`other-auctions-container other-auctions-bg ${!show && 'hidden'}`}>
                    <div className="container">
                        <h2 className="section-title">Other Auctions at This Facility</h2>

                        <div className="auctions-container">
                            <div className="auction-tiles-special-center">
                                {this.props.auctions.filter((auction) => auction.auction_id != this.props.auction_id).map((auction, i) => {
                                    if (i > 5) {
                                        return null
                                    }

                                    return (
                                        <AuctionTile key={auction.auction_id} auction={auction} otherAuctions />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="clearfix" />
                        {this.renderViewMore()}
                    </div>
                </div>
            )
        }

        return null
    }
}

const mapStateToProps = ({ page, auction, facility }, props) => ({
        containerClass: page.data.containerClass,
        auction_id: auction.auction.auction_id,
        auctions: facility.auctions,
        auction_loading: auction.loading,
        auction_error: auction.error,
        facility_loading: facility.loading_auctions,
        facility_error: facility.error,
        navigatingToAuction: auction.navigatingToAuction
    })

export default connect(mapStateToProps, { setPage })(OtherAuctions)