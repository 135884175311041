import React, { Component } from 'react';
import { connect } from 'react-redux';

import Alert from 'components/partials/Alert';

import 'styles/components/PageTitle.less';

class PageTitle extends Component {
  render() {
    if (!this.props.title && !this.props.h1) {
      // Create space on the top of the page when no page heading
      return (
        <div className="marginB20">
          <Alert />
          <div className="clearfix" />
        </div>
      );
    }

    return (
      <div className={this.props.marginBottom}>
        <Alert />
        <div className={this.props.containerClass}>
          <h1>
            {this.props.h1 || this.props.title}
          </h1>
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

const mapStateToProps = ({ page }) => ({
  title: page.data.title,
  h1: page.data.h1,
  containerClass: page.data.containerClass,
  marginBottom: page.data.marginBottom,
});

export default connect(mapStateToProps, {})(PageTitle);
