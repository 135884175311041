import {combineReducers} from 'redux';
import AuctionReducer from './AuctionReducer';
import FacilityReducer from "./FacilityReducer";
import PageReducer from "./PageReducer";
import RegistrationReducer from "./RegistrationReducer";
import AddressReducer from "./AddressReducer";
import ThingamabobReducer from "./ThingamabobReducer";
import UserReducer from "./UserReducer";
import LiveAuctionReducer from "./LiveAuctionReducer";
import NotificationReducer from "./NotifcationReducer";
import InvoiceReducer from "./InvoiceReducer";
import PaymentGatewayReducer from "./PaymentGatewayReducer";
import ReportsReducer from "./ReportsReducer";
import FormReducer from "./FormReducer";
import {cognito} from 'react-cognito';
import FileReducer from "./FileReducer";
import OfferReducer from './OfferReducer';

export default combineReducers({
    cognito,
    auction: AuctionReducer,
    live_auctions: LiveAuctionReducer,
    facility: FacilityReducer,
    page: PageReducer,
    registration: RegistrationReducer,
    address: AddressReducer,
    thingamabob: ThingamabobReducer,
    user: UserReducer,
    notification: NotificationReducer,
    invoice: InvoiceReducer,
    payment_gateway: PaymentGatewayReducer,
    form: FormReducer,
    reports: ReportsReducer,
    files: FileReducer,
    offers: OfferReducer,
});
