import React, { Component } from 'react';
import { connect } from 'react-redux';

import Alert from 'components/partials/Alert';

import 'styles/components/PageTitle.less';

class UserPageTitle extends Component {
  render() {
    const {
      marginBottom,
      containerClass,
      dashboard_title,
      pageAction,
      fm_dashboard = false,
    } = this.props;

    const dashClass = fm_dashboard ? 'fmDashTitle' : 'dashTitle';

    if (!dashboard_title) {
      // Create space on the top of the page when no page heading
      return (
        <div className="">
          <Alert />
          <div className="clearfix" />
        </div>
      );
    }

    return (
      <div className={`${marginBottom} ${dashClass}`}>
        <Alert />
        <div className={containerClass}>
          <div className="dashboard-page-title">
            {dashboard_title}
            {pageAction ? (
              <span className="page-action">
                :
                {' '}
                {pageAction}
              </span>
            ) : ''}
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

const mapStateToProps = ({ page, user }) => {
  const {
    dashboard_title,
    containerClass,
    marginBottom,
    pageAction,
  } = page.data;

  const { settings } = user;

  return {
    dashboard_title,
    containerClass,
    marginBottom,
    pageAction,
    settings,
  };
};

export default connect(mapStateToProps, {})(UserPageTitle);
