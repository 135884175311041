import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { includes } from 'lodash';

import { doLogout } from 'actions';
import { logout } from 'actions/AuthActions';

class Logout extends Component {
    state = {
        action: false,
        redirect_to: '/',
    };

    doLogout = () => {
        const { withRedirect, withReload, onClick } = this.props;

        this.props.logout();
        this.props.doLogout();
        // Clear local storage  
        localStorage.removeItem('search_obj')
        localStorage.removeItem('live_auctions_search_obj')
        
        // Logout requires a page reload to propagate everything throughout
        if (withRedirect) {
            this.props.history.replace(withRedirect);
        } else if (withReload === true) {
            window.location.reload();
        }

        if (includes(window.location.pathname, '/account')) {
            window.location.replace('/');
        }

        onClick && onClick();
    };

    render() {
        const { props, doLogout } = this;
        const { text, className } = props;

        // Original href - /account/logout
        return (
          <a href={void(0)} onClick={doLogout} className={className} title={text} id="logout">
            {text}
          </a>
        );
    }
}

Logout.defaultProps = {
    text: 'Log Out',
    className: 'btn btn-blue',
    withRedirect: '',
    withReload: true,
    onClick: false,
};

Logout.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    withRedirect: PropTypes.string,
    withReload: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapActionsToProps = {
    logout,
    doLogout,
};

export default connect(mapStateToProps, mapActionsToProps)(Logout);
