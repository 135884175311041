import MissingBlock from 'components/partials/MissingBlock'
import Pagination from 'components/partials/Pagination'
import { specialLowercase } from 'helpers/string'
import { chunk, forEach, get, has, cloneDeep } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ROW_DETAILS_TABLE_TOGGLE } from '../../Enums/table'
import 'styles/components/table.less'

/**
 * Documentation here - https://opentechlabs.atlassian.net/wiki/spaces/ST/pages/515670033/Tables
 */
class Table extends Component {
    triggerSearchChange = async (changed, newPage) => {
        await this.props.handleRedoSearch({ ...changed, currentPage: newPage || this.props.current_page })
    }

    getBodyText = (column) => {
        if (typeof column === 'string') {
            return column
        }

        return get(column, 'body', '')
    }

    /**
     *
     */
    renderTablePagination = () => {
        const {
            totalRecords, hasPagination, paginationPerPage, currentPage,
        } = this.props

        return !hasPagination ? null : (
            <Pagination
                perPage={paginationPerPage}
                dataCount={totalRecords}
                currentPage={currentPage}
                onSetPage={(newPage) => this.triggerSearchChange({}, newPage)}
            />
        )
    }

    renderTableSorting = (column_object, column_index) => {
        const { sortableColumns, sortColumn } = this.props
        const column_name = get(column_object, 'body', column_object)

        // This column is not sortable.
        if (sortableColumns !== false && sortableColumns.indexOf(column_index) < 0) {
            return [false, () => null]
        }

        // neutral sort
        if (column_name !== sortColumn.body && column_name !== sortColumn) {
            return [
                <span className="sort-arrow sort">
                    <i className="fa fa-long-arrow-up"/>
                    <i className="fa fa-long-arrow-down"/>
                </span>,
                async () => await this.triggerSearchChange({ sortColumn: { ...column_object, sort_asc: true } }),
            ]
        }

        if (sortColumn.sort_asc) {
            return [
                <i className="fa fa-sort-amount-asc sort-arrow" onClick={onclick}/>,
                async () => await this.triggerSearchChange({ sortColumn: { ...column_object, sort_asc: false } }),
            ]
        }

        // sort desc
        return [
            <i className="fa fa-sort-amount-desc sort-arrow" onClick={onclick}/>,
            async () => await this.triggerSearchChange({ sortColumn: { ...column_object, sort_asc: true } }),
        ]
    }

    renderHeader = () => {
        const {
            headers = [],
            hasSort = false,
        } = this.props

        return (
            <thead>
            <tr>
                {headers.map((item, i) => {
                    if (item === false || item == ROW_DETAILS_TABLE_TOGGLE) {
                        return (<th key={`th-empty-${i}`} className="empty-header"/>)
                    }

                    const sorting = (hasSort ? this.renderTableSorting(item, i) : '')
                    let thClass = (hasSort && sorting[0] !== false ? 'sort-arrow' : '')

                    if (has(item, 'body')) {
                        thClass += ` ${item.className}`
                        item = item.body
                    }

                    return (
                        <th key={`th-${i}`} className={thClass} onClick={sorting[1]}>
                            <span>{item}</span>
                            {' '}
                            {sorting[0]}
                        </th>
                    )
                })}
            </tr>
            </thead>
        )
    }

    setRowVisibility = (row) => {
        this.props.rowDetailsClick( this.props.viewRowDetail != row? row: null)
    }

    renderTableColumns = () => {
        const {
            headers = [],
        } = this.props

        return (
            <tbody>
            {this.props.results.map((row, row_key) => {
                const { rowLink, rowDetails = false } = row
                const rowDetailsOpened = this.props.viewRowDetail === row_key

                return (
                    <>
                        <tr key={row_key}
                            className={`${rowLink
                                ? "clickable"
                                : ""} ${rowDetails && !rowDetailsOpened
                                ? "has-row-details"
                                : ""}`}>
                            {row.columns.map((column, column_key) => {
                                const {
                                    className = "text",
                                    tableHidden = false,
                                } = column

                                if (tableHidden === true) {
                                    return null
                                }


                                const rowClick = () => this.defaultLinkClicked(
                                    row.rowLink, column) // use the default clicked function

                                if(headers[column_key] == ROW_DETAILS_TABLE_TOGGLE){
                                    return (
                                        <td key={column_key}
                                            onClick={rowClick}
                                            className={`${className} text-center`}>
                                            {rowDetails && column_key == 0 && (
                                                <a 
                                                    href={void (0)}
                                                    onClick={() => this.setRowVisibility(row_key)}
                                                >
                                                    <i className={`fa fa-${this.props.viewRowDetail ==
                                                    row_key
                                                        ? 'chevron-up'
                                                        : 'chevron-down'}`} />
                                                </a>
                                            )}
                                        </td>
                                    )
                                }

                                // if key of body is in the object then it's a user definied row
                                const body = typeof column === "object" &&
                                    "body" in column
                                        ? column.body
                                        : column

                                return (
                                    <td key={column_key}
                                        onClick={rowClick}
                                        className={className}>
                                        {body}
                                    </td>)
                            })}
                        </tr>
                        {(rowDetails && rowDetailsOpened) && (
                            <tr className="row-details">
                                <td colSpan={100}>
                                    {rowDetails}
                                </td>
                            </tr>
                        )}
                    </>
                )
            })}
            </tbody>
        )
    }

    renderTable = ({ empty_results = false } = {}) => {
        const tableClass = `${this.props.baseTableClass} ${this.props.className}`

        const tableTitle = !this.props.tableTitle ? ''
            : (<div className="table-title">{this.props.tableTitle}</div>)

        const tableFooter = !this.props.cardFooter ? ''
            : (<div className="card-footer">{this.props.cardFooter}</div>)

        if (empty_results) {
            return (
                <div className="table-container is-table empty-table">
                    {tableTitle}
                    <table className={tableClass}>
                        <tbody>
                        <tr key="no-rows" className="no-rows">
                            <td colSpan="100" className="no-results-td">
                                {this.props.emptyRows}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {tableFooter}
                </div>
            )
        }

        return (
            <div className="table-container is-table">
                {tableTitle}
                <table className={tableClass}>
                    {this.renderHeader()}
                    {this.renderTableColumns()}
                </table>
                {this.renderTablePagination()}
                {tableFooter}
            </div>
        )
    }

    findCardTitle = (card) => {
        let foundTitle = ''
        let cardTitleIndex = -1
        forEach(card.columns, ({ cardTitle = false }, i) => {
            if (cardTitle) {
                foundTitle = get(this.props.headers, i, '')

                if (typeof foundTitle === 'object') {
                    foundTitle = foundTitle.body
                }

                cardTitleIndex = i

                foundTitle += (foundTitle ? ': ' : '') + cardTitle
                return true // break the foreach loop
            }
        })

        return [foundTitle, cardTitleIndex]
    }

    renderXLCards = () => {
        const {
            results: _results = [],
            hasActions = true,
            fmCards = false,
            headers: _headers = [],
            addTitleAdjust
        } = this.props

        const headers = cloneDeep(_headers)
        const results = cloneDeep(_results)
        let removedHeader = !addTitleAdjust

        return (
            <div className="table-container is-card card-container">
                {results.map((card, card_index) => {
                    const cardColumns = [...card.columns]
                    const { rowDetails } = card
                    let img = null

                    const actions = (hasActions ? cardColumns.pop() : null)

                    const [cardTitle, cardTitleIndex] = this.findCardTitle(card)

                    if (cardTitleIndex >= 0) {
                        if (removedHeader === false) {
                            removedHeader = true
                            headers.splice(cardTitleIndex, 1)
                        }
                        cardColumns.splice(cardTitleIndex, 1)
                    }

                    if (this.props.withImg) {
                        img = cardColumns.splice(0, 1)
                        // headers.splice(0, 1)
                    }

                    const columnsForCards = cardColumns.filter(({ cardHidden = false }, index) => {
                        if (cardHidden) {
                            delete headers[index+1]//.splice(index+1, 1)
                        }

                        if(headers[index] === ROW_DETAILS_TABLE_TOGGLE){
                            return false
                        }

                        return !cardHidden
                    })
                    const chunks = chunk(columnsForCards, Math.ceil(columnsForCards.length / 2))

                    const rowClick = () => this.defaultLinkClicked(card.rowLink) // use the default clicked function

                    const cardTitleHtml = cardTitle ? (<div key={`card-${card_index}-title`} className="card-title"
                                                            onClick={rowClick}>{specialLowercase(cardTitle)}</div>) : ''

                    const hasRowDetails = rowDetails? 'has-row-details': ''

                    return (
                        <div key={`card-${card_index}`} className={`card ${this.props.className} xlCards ${hasRowDetails}`}>
                            {cardTitleHtml}
                            <div className="card-body" key={`card-${card_index}-card-body`}>
                                {img && React.cloneElement(img[0].body, { has_ribbon: false, needs_bottom: true })}
                                <div className="row card-row" key={`card-${card_index}-card-row`}>
                                    <div className={`col-sm-${img ? (actions ? 5 : 6) : (actions ? 4 : 6)}`}>
                                        {chunks[0]?.map((column, i) => {
                                            i++

                                            const {
                                                body = column,
                                                className = '',
                                            } = column

                                            // for auction cards if the column body is empty then don't output it.
                                            // Like if store id is empty then skip it
                                            if (!body) {
                                                return null
                                            }

                                            const rowClick = () => this.defaultLinkClicked(card.rowLink, column) // use the default clicked function

                                            const { titleHtml, cardBodyTitleClass } = this.buildTableProps(i, rowClick, headers)

                                            const clearFix = fmCards ? (<div className="clearfix"/>) : null

                                            return (
                                                <>
                                                    <div
                                                        key={`card-${i}-body`}
                                                        className={cardBodyTitleClass}
                                                        onClick={rowClick}
                                                    >
                                                        {titleHtml}
                                                        <span className={`body ${className}`}>
                                                            {this.getBodyText(column)}
                                                        </span>
                                                    </div>
                                                    {clearFix}
                                                </>
                                            )
                                        })}
                                    </div>
                                    <div className={`col-sm-${img ? (actions ? 4 : 6) : (actions ? 4 : 6)}`}>
                                        {chunks[1] && chunks[1].map((column, i) => {
                                            const {
                                                classname = '',
                                            } = column

                                            i = i + Math.ceil(columnsForCards.length / 2) 
                                            i++

                                            if (i === cardTitleIndex) {
                                                return (<span key={`column-${i}-removed`} className="column-removed"/>)
                                            }

                                            const rowClick = () => this.defaultLinkClicked(card.rowLink, column) // use the default clicked function

                                            const { titleHtml, cardBodyTitleClass } = this.buildTableProps(i, rowClick, headers)

                                            const clearFix = this.props.fmCards ? (
                                                <di className="clearfix"/>) : null

                                            return (
                                                <>
                                                    <div
                                                        key={`card-${i}-body`}
                                                        className={cardBodyTitleClass}
                                                        onClick={rowClick}
                                                    >
                                                        {titleHtml}
                                                        <span className={`body ${classname}`}>
                                                            {this.getBodyText(column)}
                                                        </span>
                                                    </div>
                                                    {clearFix}
                                                </>
                                            )
                                        })}
                                    </div>
                                    {actions
                                    && (
                                        <div className={`col-sm-${img ? 3 : '4 actions-menu'}`}>
                                            {/* get(this.props, 'fmCards', false) ? actions.body : actions */}
                                            {actions?.body !== undefined? actions.body: actions}
                                            {rowDetails && <div className="marginB20" />}
                                        </div>
                                    )}
                                </div>
                                {rowDetails && (
                                    <button
                                        onClick={() => this.setRowVisibility(card_index)}
                                        className="btn btn-sm btn-default row-details-toggler">
                                        <i
                                            className={`fa fa-chevron-${this.props.viewRowDetail == card_index ? 'up' : 'down'}`}
                                        />
                                    </button>
                                )}
                            </div>
                            {(rowDetails && this.props.viewRowDetail == card_index) && (
                                <div className="card-body card-details">
                                    {rowDetails}
                                </div>
                            )}
                        </div>
                    )
                })}

                {this.renderTablePagination()}
            </div>
        )
    }

    buildTableProps (i, rowClick, headers = this.props.headers) {
        let {
            [i]: title = ''
        } = headers

        let titleHtml = null
        let cardBodyTitleClass = 'card-body-row'
        let titleClass = ''

        if (typeof title === 'object') {
            const { className, body } = title

            titleClass = ` ${className}`
            title = body
        }

        if (title) {
            titleHtml = (
                <div className={`title${titleClass}`} onClick={rowClick}>
                    {specialLowercase(title)}
                    :
                </div>
            )
        } else {
            cardBodyTitleClass += ' card-no-header'
        }

        return { titleHtml, cardBodyTitleClass }
    }

    renderCards = () => {
        const { results } = this.props
        return (
            <div className="table-container is-card card-container">
                {results.map((card, card_index) => {
                    const [cardTitle, cardTitleIndex] = this.findCardTitle(card)

                    const rowClick = () => this.defaultLinkClicked(card.rowLink) // use the default clicked function

                    const cardTitleHtml = cardTitle
                        ? (<div className="card-title" onClick={rowClick}>{specialLowercase(cardTitle)}</div>)
                        : ''

                    return (
                        <div key={`card-${card_index}`} className={`card ${this.props.className}`}>
                            {cardTitleHtml}
                            <div className="card-body">
                                {card.columns.map((column, i) => {
                                    const { body = column, className = '' } = column

                                    // for auction cards if the column body is empty then don't output it.
                                    // Like if store id is empty then skip it
                                    if (!body) {
                                        return null
                                    }

                                    if (typeof column === 'object' && column.cardHidden === true) {
                                        return (<span key={`column-${i}-removed`} className="column-removed"/>)
                                    }

                                    if (i === cardTitleIndex) {
                                        return (<span key={`column-${i}-removed`} className="column-removed"/>)
                                    }

                                    const rowClick = () => this.defaultLinkClicked(card.rowLink, column) // use the default clicked function

                                    const { titleHtml, cardBodyTitleClass } = this.buildTableProps(i, rowClick)

                                    return (
                                        <div 
                                            key={`card-${i}-body`} 
                                            className={cardBodyTitleClass}
                                            onClick={rowClick}
                                        >
                                            {titleHtml}
                                            <span className={`body ${className}`}>
                                                {this.getBodyText(column)}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}

                {this.renderTablePagination()}
            </div>
        )
    }

    defaultLinkClicked = (link, { rowLink = true } = {}) => {
        if (rowLink && typeof link === 'function') {
            link()
        }
    }

    render () {
        const {
            enableTable,
            canRespond,
            windowWidth,
            screen,
            respondAt,
            results = [],
            xlCards = false
        } = this.props

        // Empty tables or cards look the same
        if (results.length == 0) {
            return this.renderTable({ empty_results: true })
        }

        if(canRespond) {
            let responseAt = get(screen, respondAt)
            if (responseAt) {
                responseAt -= 1
            } else {
                responseAt = this.props.respondAt
            }

            if (windowWidth <= (responseAt - 1)) {
                return (xlCards ? this.renderXLCards() : this.renderCards())
            }
        }
        return enableTable? this.renderTable(): this.renderXLCards() //prop enableTable false for XLCards only, for code review
        //return this.renderTable()
    }
}

Table.defaultProps = {
    withImg: false,
    baseTableClass: 'table table-hover table-stripped',
    className: '',

    enableTable: true,
    tableTitle: false,
    headers: [],

    hasPagination: true,
    paginationPerPage: 50,
    totalRecords: -1,
    currentPage: 1,

    canRespond: true,
    respondAt: 'xs',

    hasSort: false,
    sortColumn: {},
    sortableColumns: false,

    cardFooter: false,
    addTitleAdjust: false,

    emptyRows: (<MissingBlock />),

    handleRedoSearch: () => {
        alert('missing the handleRedoSearch function')
        // empty function to prevent undefined error
    }
}

const mapStateToProps = ({ page }, props) => ({
    windowWidth: page.windowWidth,
    screen: page.screen,
    history: props.history,
    xlCards: props.xlCards
})

export default connect(mapStateToProps)(Table)