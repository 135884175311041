import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setThingamabobProp } from 'actions';

import { get } from 'lodash';

import 'styles/components/Thingamabob.less';
import { is_user_suspended } from 'helpers/user';
import { sim_uploads } from 'services/BaseFileService';
import storage from '../../helpers/storage';
import { APP_CONFIG } from '../../Config';

class Thingamabob extends Component {
    state = {
        windowWidth: null,
        screenSize: null,
        sim_uploads: 'Not Initialized',

        open_close_button_bg_color: '',
    };

    componentDidMount = () => {
        const {
            APP_COLOR: open_close_button_bg_color = 'rgba(255, 255, 255, .8)',
        } = APP_CONFIG.DEV_SETTINGS || {};
        const { tab } = this.props;

        if (tab) {
            setTimeout(() => window.jQuery(`.${tab}`).click(), 100);
        }        

        this.setState({ open_close_button_bg_color });

        this.getScreenWidth();
        window.addEventListener('resize', this.getScreenWidth);

        sim_uploads.callback = (sim_uploads) => this.setState({ sim_uploads });
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.getScreenWidth);

        sim_uploads.callback = null;
    };

    getScreenWidth = () => {
        const size = window.innerWidth;
            let screenSize = 'lg';

        if (size <= 320) {
            screenSize = 'xxs';
        } else if (size > 320 && size <= 480) {
            screenSize = 'xs';
        } else if (size > 480 && size <= 768) {
            screenSize = 'sm';
        } else if (size > 768 && size <= 1200) {
            screenSize = 'md';
        } else if (size > 1200) {
            screenSize = 'lg';
        }

        this.setState({
            windowWidth: window.innerWidth,
            screenSize,
        });
    };

    onChange = (name, value) => {
        this.props.setThingamabobProp(name, value);

        storage.setItem(`st.thingamabob.${name}`, value);
    };

    toggleShow = () => this.onChange('show', this.props.show !== 'open' ? 'open' : 'closed');

    changePosition = () => this.onChange('position', this.props.position !== 'right' ? 'right' : 'left');

    renderActions = () => get(this.props, 'actions', []).map((item, index) => {
            if (get(item, 'legend') === true) {
                // render section label
                return (
                  <legend key={index}>{item.label}</legend>
                );
            }

            // render special elements (buttons,text box,text,etc)
            return (
              <Fragment key={index}>
                {item}
              </Fragment>
            );
        });

    render() {
        const {
            position,
            show,
            user_id,
            email,
            timezone,
            reg_type_label,
            authed,
        } = this.props;
        const { sim_uploads } = this.state;

        return (
          <>
            <div className={`showhidebutton ${position} ${show}${authed ? ' text-success' : ''}`} onClick={this.toggleShow} style={{ background: this.state.open_close_button_bg_color }}>
              <i className="fa fa-cogs" />
            </div>
            <div className={`thingamabob ${position} ${show}`}>
              <div className="well well-sm" style={{ background: '#fff' }}>
                <legend>
                  Thingamabob
                  <div className="pull-right">
                    <button className="btn btn-xs btn-primary" onClick={this.changePosition}>
                      <i className="fa fa-arrow-left" />
                      <i className="fa fa-arrow-right" />
                    </button>
                    <button className="btn btn-xs btn-warning" onClick={this.toggleShow}>
                      <i className="fa fa-times" />
                    </button>
                  </div>
                </legend>

                <div className="clearfix" />
                <div style={{ overflow: 'hidden' }}>
                  <div className="row">
                    <ul className="nav nav-tabs nav-justified tabs-justified marginT5" role="tablist">
                      <li className="active">
                        <a href="#thinamabob-user" role="tab" data-toggle="tab" className="tab-thinamabob-user" onClick={() => this.onChange('tab', 'tab-thinamabob-user')}>
                          <i className="fa fa-users" />
                        </a>
                      </li>
                      <li>
                        <a href="#page-settings" role="tab" data-toggle="tab" className="tab-page-settings" onClick={() => this.onChange('tab', 'tab-page-settings')}>
                          <i className={`fa fa-file ${ get(this.props, 'actions', []).length > 0 ? ' text-info' : ''}`} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <br />
                <div className="tab-content marginT5">
                  <div className="active tab-pane fade in" id="thinamabob-user">
                    <dl className="dl-horizontal">
                      <dt style={{ width: 100 }}>Authed</dt>
                      <dd style={{ marginLeft: 120 }} className={authed ? 'text-success' : 'text-danger'}>{authed ? 'Yes' : 'No'}</dd>
                      {user_id ? (
                        <>
                          <dt style={{ width: 100 }}>User Type</dt>
                          <dd style={{ marginLeft: 120 }}>{reg_type_label}</dd>
                          <dt style={{ width: 100 }}>User ID</dt>
                          <dd className="selectable" style={{ marginLeft: 120 }}>{user_id || 'N/A'}</dd>
                          <dt style={{ width: 100 }}>Email</dt>
                          <dd className="selectable" style={{ maxWidth: '100%', overflow: 'auto', marginLeft: 120 }}>{email || 'N/A'}</dd>
                          <dt style={{ width: 100 }}>Suspended</dt>
                          <dd style={{ maxWidth: '100%', overflow: 'auto', marginLeft: 120 }}>{is_user_suspended() ? 'Yes' : 'No'}</dd>
                        </>
) : null}
                      <dt style={{ width: 100 }}>Timezone</dt>
                      <dd style={{ maxWidth: '100%', overflow: 'auto', marginLeft: 120 }}>{timezone || 'America/Phoenix'}</dd>
                    </dl>
                    <br />
                    <legend>Window size</legend>
                    <dl className="text-center">
                      <dt>Window Width</dt>
                      <dd>{this.state.windowWidth || 'N/A'}</dd>
                      <dt>Screen Size</dt>
                      <dd>{`screen-${ this.state.screenSize}` || 'N/A'}</dd>
                      <dt>Page Container</dt>
                      <dd>{this.props.containerClass}</dd>
                    </dl>
                    <br />
                    <legend>Other Cool information</legend>
                    <dl className="text-center">
                      <dt>Sim Uploads</dt>
                      <dd>{sim_uploads}</dd>
                    </dl>
                  </div>
                  <div className="tab-pane fade text-center" id="page-settings" title="Page level options">
                    {this.renderActions()}
                  </div>
                </div>


              </div>
            </div>
          </>
        );
    }
}

const mapStateToProps = ({ thingamabob, user }) => {
    const {
        position,
        show,
        actions,
        containerClass,
    } = thingamabob || {};

    const {
        user_id = '',
        email = '',
        timezone = 'America/Phoenix',
        reg_type_label = '',
        authed = false,
    } = user.user || {};

    return {
        position,
        show,
        actions,
        containerClass,
        authed,
        user_id,
        email,
        timezone,
        reg_type_label,
    };
};

export default connect(mapStateToProps, { setThingamabobProp })(Thingamabob);
