import React from 'react';

const Loading = ({ show = true, loading_component = [{ loading: true, label: 'Loading' }] }) => (!show ? null : (
  <div id="loading" className="loading text-center">
    {loading_component.map(({ loading, label } = {}, index) => (
      <h3 id={`loading-${index}`} key={`loading-${index}`}>
        <i className={`fa fa-${loading ? ' fa-spinner fa-spin' : 'check'}`} />
        {' '}
        {label}
      </h3>
      ))}
  </div>
));

export default Loading;
