import { template as _template } from 'lodash';

export const randomString = () => (
    Math.random().toString(36).substring(2, 15)
);

export const ucfirst = (theString) => {
    if (!theString) {
        return '';
    }

    try {
        return `${theString}`.charAt(0).toUpperCase() + `${theString}`.substr(1)
    } catch (e) {
        return theString;
    }
};

export const specialLowercase = (str) => str.toLowerCase().replace(/ id/, ' ID');

export const slugify = (str) => (str || '').toLowerCase().replace(/[\W_]+/g,"-").replace(/-$/, '');

export const formatLocation = ({city = undefined, state}) => `${city}${city ? ', ' : ''}${state}`

export const template = (templateString, object) => {
    const compiled = _template(templateString);
    return compiled(object);
};

export const capitalizeWords = str => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
