import React from 'react'
import BaseSelect from './BaseSelect'
import { Field } from 'formik'

const Select = ({
    formik, 
    ...props
}) => {
    if (formik) {
        return <Field 
            name={props.name}
        >
            {({field, form}) => {
                return (
                    <BaseSelect error={form?.touched[props.name] && form?.errors[props.name]} {...props} {...field} />
                )
            }}
        </Field>
    }
    
    return (
        <BaseSelect {...props} />
    )
}

export default Select
