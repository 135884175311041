import { Modal } from 'react-bootstrap'
import Button from 'components/partials/Fields/Button'
import Cancel from 'components/partials/Fields/Cancel'
import { Row } from 'react-bootstrap'

const ConfirmationModal = ({ 
    show,
    title = 'Confirm Invite',
    text = 'Are you sure you want to send this Authorized Pickup Person invite?',
    submitLabel = 'Send',
    loading = false,
    handleConfirm = () => {}, 
    setShow = () => {},
    setEmail = () => {}
}) => {

    const handleHide = () => {
        setShow(false)
        setEmail('')
    }

    const handleCancelInvite = () => {
        setShow(false)
        setEmail('')
    }

    return (
        <Modal
            name="confirmation-modal"
            show={show}
            className="confirmation-modal"
            onHide={handleHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                <Row className="marginB20">
                    <Button
                        label={submitLabel}
                        className="btn btn-blue btn-regular btn-fw marginT20"
                        onClick={handleConfirm}
                        loading={loading}
                        disabled={loading}
                    />
                </Row>
                <Row className='text-center'>
                    <Cancel
                        okAction={handleCancelInvite}
                        label="Cancel"
                        id="cancel"
                        cancelConfirmation={false}
                    />
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal