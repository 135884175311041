import LoginService from "services/LoginService";
// import {USER_LOGIN} from "./UserTypes";

export const login = ({email, password}) => async () => new Promise((resolve, reject) => {
    LoginService.login(email.trim().toLowerCase(), password.trim()).then(
        success => resolve(success),
        error => reject(error)
    );
});

export const logout = () => async () => new Promise((resolve, reject) => {
    LoginService.logout().then(
        success => resolve(success),
        error => reject(error)
    );
});
