import React, { createContext, useState, useContext, useEffect } from 'react'
import useService from 'hooks/useService'
import storage from 'helpers/storage'

export const GlobalContext = createContext({})

export const useGlobalContext = () => useContext(GlobalContext)

export const GlobalProvider = ({ userId, children }) => {
    const [_, fireGetUser] = useService()
    const [__, fireGetCountries] = useService()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({})
    const [countries, setCountries] = useState(false)

    const setPaymentMethodInStorage = paymentMethod => {
        storage.setItem('st.payment_method', paymentMethod !== false ? "1" : "0")
    }

    const getUser = async () => {
        if (loading) return

        setLoading(true)
        try {
            const { user: _user } = await fireGetUser({
                url: `users/account`,
                params: { scope: 'account-settings-form' }
            })
            setUser(_user)
            setPaymentMethodInStorage(_user.payment_method)
        } finally {
            setLoading(false)
        }
    }

    const getCountries = async () => {
        setLoading(true)
        try {
            const { countries } = await fireGetCountries({
                url: 'lists/countries'
            })
            setCountries(countries)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (userId && !user.user_id && !loading) {
            getUser()
        }
    }, [userId])

    return (
        <GlobalContext.Provider
            value={{
                countries,
                user,
                loading,
                setCountries,
                setUser,
                setLoading,
                getUser,
                getCountries
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider
