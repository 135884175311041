import { Component } from 'react'
import { connect } from 'react-redux'
import Link from 'components/Link'
import HelpLink from '../components/HelpLink'
import PhoneLink from '../components/PhoneLink'
import 'styles/components/Footer.less'

const storageWars = '/static/images/community-icons/storage_wars.png'
const foxNews = '/static/images/community-icons/fox-news-logo.svg'
const abcBroadcast = '/static/images/community-icons/abc-broadcast-logo.png'
const KTTVFox = '/static/images/community-icons/KTTV_Fox_11_logo.png'

class Footer extends Component {
    addspace = () => {
        if (this.props.windowWidth < 768) {
            return (
                <div style={{ height: 10 }} />
            )
        }

        return null
    }

    render() {
        const current = new Date()

        const {
            add_bottom_top,
            showLinks = true,
            showRegisterNow = true,
            showCopyRight = true
        } = this.props

        return (
            <footer className={add_bottom_top ? 'marginT10' : ''}>
                <div className="clearfix" />

                <div className="top text-center bg-primary">
                    <div>
                        <h2>Got questions? We&apos;re here for you!</h2>
                        <h3 className="contact-info">
                            <PhoneLink append=" or "/> <HelpLink url="/requests/new">Submit a request</HelpLink>
                        </h3>
                    </div>
                </div>

                {showLinks && (
                    <div className="links-container marginT20">
                        <div className={this.props.containerClass}>
                            <div className="col-lg-offset-1">
                                <div className="col-xxs-12 col-xs-6 col-sm-4 col-md-3 col-lg-3 paddingT5">
                                    <div className="title">
                                        Auctions &amp; Listings
                                    </div>
                                    <ul className="body list-unstyled">
                                        <li>
                                            <Link
                                                to="/auctions"
                                                onClick={() => window.localStorage.removeItem('search_obj')}
                                            >
                                                Online Storage Auctions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/live-auctions"
                                                onClick={() => window.localStorage.removeItem('live_auctions_search_obj')}
                                            >
                                                Live Auction Listing
                                            </Link>
                                        </li>
                                        <li>{(this.props.user.reg_type === '1') ?
                                            <Link to="/account/auctions/active">View My Auctions</Link>
                                            : (this.props.user.reg_type) ?
                                                <Link to="/account/dashboard">View My Auctions</Link>
                                                :
                                                <a href="/account/dashboard">View My Auctions</a>
                                        }</li>
                                    </ul>
                                </div>
                                <div className="col-xxs-12 col-xs-6 col-sm-4 col-md-3 col-lg-3 paddingT5">
                                    <div className="title">
                                        Pro Community
                                    </div>
                                    <ul className="body list-unstyled">
                                        <li><a href="http://blog.storagetreasures.com/" rel="noopener noreferrer" target="_blank" id="footer-auction-pro">Auction Pro Blog</a></li>
                                        <li><Link to="/pro-membership-upgrade" id="footer-upgrade">Upgrade To Pro</Link></li>
                                        <li><Link to="/online-storage-auction-app" id="footer-auction-app">StorageTreasures App</Link></li>
                                    </ul>
                                    {/* Need to create space to push down elements */}
                                    <div className="visible-xs-special" />
                                </div>
                                <div className="col-xxs-12 col-xs-6 col-sm-4 col-md-3 col-lg-3 paddingT5">
                                    <div className="title">
                                        About Us
                                    </div>
                                    <ul className="body list-unstyled">
                                        <li><Link to="/about">StorageTreasures</Link></li>
                                        <li><a href="https://www.opentechalliance.com/about-us/" target="_blank" rel="noopener noreferrer">OpenTech Alliance</a></li>
                                        <li><a href="https://www.charitystorage.org" target="_blank" rel="noopener noreferrer">Charity Storage</a></li>
                                    </ul>
                                </div>
                                <div className="col-xxs-12 col-xs-6 col-sm-4 col-md-3 col-lg-3 paddingT5">
                                    <div className="title">
                                        Need Help?
                                    </div>
                                    <ul className="body list-unstyled">
                                        <li><HelpLink showSupportEmail={false}>Help Center</HelpLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showRegisterNow && (
                    <div className="margin10 marginT20 marginB30 register-button-container">
                        <Link
                            id="footer-register"
                            to="/register"
                            className="btn btn-register btn-orange btn-block btn-lg text-center"
                        >
                            Register Now - It&apos;s Easy &amp; Free!
                        </Link>
                    </div>
                )}
                
                {showLinks || showRegisterNow && <hr className="marginB30"/>}

                <div className="community-links text-center col-md-12">
                    <div className="title marginR10">Our Community:</div>
                    {this.addspace()}
                    <a target="_blank" href="https://twitter.com/StorageTreasure" rel="noopener noreferrer">
                        <i className="fa fa-2x fa-twitter" />
                    </a>
                    <a target="_blank" href="https://www.facebook.com/storagetreasures" rel="noopener noreferrer">
                        <i className="fa fa-2x fa-facebook" />
                    </a>
                    <a target="_blank" href="https://www.youtube.com/user/storagetreasures" rel="noopener noreferrer">
                        <i className="fa fa-2x fa-youtube" />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/storage-treasures-inc." rel="noopener noreferrer">
                        <i className="fa fa-2x fa-linkedin" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/storagetreasures/" rel="noopener noreferrer">
                        <i className="fa fa-2x fa-instagram" />
                    </a>
                    <br className="visible-xxs visible-xs" />
                    <span className="visible-xxs visible-xs marginT15" />
                    <span className="hidden-xxs hidden-xs marginR30 marginL30" />
                    <div className="title">As Seen On:</div>
                    {this.addspace()}
                    <img className="icon" src={storageWars} alt="Storage War logo" />
                    <img className="icon" src={foxNews} alt="Fox News Channel logo" />
                    <img className="icon" src={abcBroadcast} alt="ABC Television Network logo" />
                    <img className="icon" src={KTTVFox} alt="Fox 11 News Los Angeles logo" />
                </div>

                <div className="clearfix" />

                {showCopyRight && (
                    <div className="copyright-bar text-center marginT30 row-xs links-reverse-hover">
                        <p>
                            StorageTreasures, LLC ©
                            {' '}
                            {current.getFullYear()}
                            {' '}
                            |
                            {' '}
                            <a href="/accessibility" target="_blank" rel="nofollow noreferrer"><strong>Accessibility</strong></a>
                            ,&nbsp;
                            <a href="/terms" target="_blank" rel="nofollow noreferrer"><strong>Terms of Use</strong></a>
                            ,&nbsp;
                            <a href="/dmca" target="_blank" rel="nofollow noreferrer"><strong>DMCA</strong></a>
                            &nbsp;&amp;&nbsp;
                            <a href="/privacy" target="_blank" rel="nofollow noreferrer"><strong>Privacy Policy</strong></a>
                            &nbsp;|&nbsp;
                            Powered by
                            {' '}
                            <a href="http://www.opentechalliance.com/" target="_blank" rel="noopener noreferrer"><strong>OpenTech Alliance Inc</strong></a>
                        </p>
                    </div>
                )}
            </footer>
        )
    }
}

Footer.defaultProps = {
    add_bottom_top: true
}

const mapStateToProps = ({ page, user }) => ({
    containerClass: page.data.containerClass,
    windowWidth: page.windowWidth,
    user: user,
    settings: user.settings
})

export default connect(mapStateToProps)(Footer)
