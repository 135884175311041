import {
    BOOKMARK_FACILITY,
    BOOKMARK_FACILITY_FAIL,
    BOOKMARK_FACILITY_SUCCESS,
    FACILITY_LOAD_FEATURES,
    FACILITY_LOAD_FEATURES_FAIL,
    FACILITY_LOAD_FEATURES_SUCCESS,
    GET_FACILITIES_FAIL,
    GET_FACILITY,
    GET_FACILITY_LIVE_AUCTIONS,
    GET_FACILITY_LIVE_AUCTIONS_FAIL,
    GET_FACILITY_LIVE_AUCTIONS_SUCCESS,
    GET_FACILITY_ONLINE_AUCTIONS,
    GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON,
    GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_FAIL,
    GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_SUCCESS,
    GET_FACILITY_ONLINE_AUCTIONS_FAIL,
    GET_FACILITY_ONLINE_AUCTIONS_SUCCESS,
    GET_FACILITY_PMS,
    GET_FACILITY_SUCCESS,
    GET_USER_FACILITIES,
    GET_USER_FACILITIES_FAIL,
    GET_USER_FACILITIES_SUCCESS,
    GET_USER_FACILITY,
    GET_USER_FACILITY_FAIL,
    GET_USER_FACILITY_SUCCESS,
    SET_FACILITY_EDIT_FORM_DATA
} from "actions/FacilityTypes";

import {get, set} from "lodash";

const INITIAL_STATE = {
    loading: true,
    loading_count: 0,

    loading_auctions: true,
    loading_auctions_coming_soon: true,
    loading_auctions_live: true,

    bookmark_loading: false,
    error: null,
    data: {
        facility: {},
        facility_features: [],
        facility_hours: []
    },
    auctions: [],
    auctions_total_records: 0,
    live_auctions: [],
    auctions_live_total_records: 0,
    auctions_coming_soon: [],
    auctions_coming_soon_total_records: 0,

    pms_loading: false,
    pms_loading_error: null,
    pms_systems: [],

    facility_list: [],
    loading_facilities_error: false,
    loading_facilities: false,

    facility_features: [],
    facility_features_loading: false,
    facility_features_error: false,

    cleanout_times: [
        {name: '24 Hours', id: '24'},
        {name: '48 Hours', id: '48'},
        {name: '72 Hours', id: '72'},
    ],

    form_data_loading: true,
    form_data_error: false,
    form_data: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_FACILITY:
            let defaultState = {
                facility: {},
                facility_features: [],
                facility_hours: []
            };

            return {...state, loading: true, error: null, data: defaultState};

        case GET_FACILITY_SUCCESS:

            state.data.facility = get(action, 'payload.facility', {});

            let hoursArray = Object.keys(get(action.payload, 'facility.hours', [])).map(i => get(action.payload, 'facility.hours', [])[i]);

            let markers = get(action, 'payload.facility.marker.position', ",").split(",");
            state.data.facility.marker = {lat: markers[0], lng: markers[1]};
            state.data.facility_features = get(action.payload, 'facility_features', []);
            state.data.facility_hours = hoursArray.map((hour) => {
                return {...hour, day_of_week: get(action.payload, 'days_of_week.' + hour.day_of_week)}
            });

            return {...state, loading: false, error: null};
        case GET_FACILITIES_FAIL:
            return {...state, loading: false, error: "Failed to retrieve the facility details"};

        case BOOKMARK_FACILITY:
            return {...state, bookmark_loading: true};
        case BOOKMARK_FACILITY_SUCCESS:
            state.data.facility.bookmarked = action.direction;

            const message = action.direction ?
                "You have added this facility to your bookmarks." :
                "You have removed this facility from your bookmarks.";
            typeof window != 'undefined' && window.toastr['success'](message, "Success!");

            if (!action.direction) {
                set(state, 'data.facility.user.bookmark_id', false); //remove the bookmark id
            } else {
                set(state, 'data.facility.user.bookmark_id', get(action, 'payload.bookmark_id', false));
            }
            set(state, 'data.facility.user.bookmark', action.direction); //remove the bookmark id

            return {...state, bookmark_loading: false};
        case BOOKMARK_FACILITY_FAIL:
            typeof window != 'undefined' && window.toastr['error']("There was an issue saving your bookmark", "Oops!");
            return {...state, bookmark_loading: false, error: action.error};

        case GET_FACILITY_ONLINE_AUCTIONS:
            return {...state, loading_auctions: true, error: null, auctions_total_records: 0};
        case GET_FACILITY_ONLINE_AUCTIONS_SUCCESS:
            return {...state, loading_auctions: false, error: null, auctions_total_records: get(action, 'payload.total_records', 0), auctions: get(action, 'payload.auctions', [])};
        case GET_FACILITY_ONLINE_AUCTIONS_FAIL:
            return {...state, loading_auctions: false, error: "Failed to retrieve the facility online auctions"};

        case GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON:
            return {...state, loading_auctions_coming_soon: true, error: null, auctions_coming_soon: [], auctions_coming_soon_total_records: 0};
        case GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_SUCCESS:
            return {...state, loading_auctions_coming_soon: false, error: null, auctions_coming_soon_total_records: get(action, 'payload.total_records', 0), auctions_coming_soon: get(action, 'payload.auctions', [])};
        case GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_FAIL:
            return {...state, loading_auctions_coming_soon: false, error: "auctions_coming_soon_fail", auctions_coming_soon: []};

        case GET_FACILITY_LIVE_AUCTIONS:
            return {...state, loading_auctions_live: true, error: null, live_auctions: [], auctions_live_total_records: 0};
        case GET_FACILITY_LIVE_AUCTIONS_SUCCESS:
            return {...state, loading_auctions_live: false, error: null, auctions_live_total_records: get(action, 'payload.total_records', 0), live_auctions: get(action, 'payload.live_auctions', [])};
        case GET_FACILITY_LIVE_AUCTIONS_FAIL:
            return {...state, loading_auctions_live: false, error: GET_FACILITY_LIVE_AUCTIONS_FAIL, live_auctions: []};

        case GET_FACILITY_PMS:
            return {...state, pms_loading: true, pms_loading_error: null, pms_systems: []};
        case GET_FACILITY_PMS + "_SUCCESS":
            return {...state, pms_loading: false, pms_loading_error: null, pms_systems: get(action, 'pms_systems', [])};
        case GET_FACILITY_PMS + "_FAIL":
            return {...state, pms_loading: false, pms_loading_error: action.payload};

        case SET_FACILITY_EDIT_FORM_DATA:

            if (action.clearForm === true) {
                return {...state, form_data: {}};
            }

            return {...state, form_data: {...state.form_data, [action.name]: action.value}};

        case GET_USER_FACILITIES:
            return {...state, facility_list: [], loading_facilities_error: false, loading_facilities: true};
        case GET_USER_FACILITIES_SUCCESS:
            const {facilities = []} = action.payload;

            return {...state, facility_list: facilities, loading_facilities_error: false, loading_facilities: false};
        case GET_USER_FACILITIES_FAIL:
            return {...state, loading_facilities_error: action.payload, loading_facilities: false};

        case FACILITY_LOAD_FEATURES:
            return {...state, facility_features: [], facility_features_loading: true, facility_features_error: false};
        case FACILITY_LOAD_FEATURES_SUCCESS:
            return {...state, facility_features: action.payload.facility_features, facility_features_loading: false};
        case FACILITY_LOAD_FEATURES_FAIL:
            return {...state, facility_features: [], facility_features_loading: false, facility_features_error: action.payload.error};

        case GET_USER_FACILITY:
            return {...state, form_data: [], form_data_loading: true, form_data_error: false};
        case GET_USER_FACILITY_SUCCESS:
            if (action.only_image_update) {
                return {...state,
                    form_data: {
                        ...state.form_data,
                        ad_file: action.payload.facility.ad_file,
                        ad_id: action.payload.facility.ad_id,
                        logo_file: action.payload.facility.logo_file,
                        logo_file_id: action.payload.facility.logo_id
                    },
                    form_data_loading: false
                };
            }

            return {...state, form_data: action.payload.facility, form_data_loading: false};
        case GET_USER_FACILITY_FAIL:
            return {...state, form_data_loading: false, form_data_error: action.error};

        default:
            return state;
    }
}
