import {GET_INVOICE, GET_INVOICE_FAIL, GET_INVOICE_SUCCESS, GET_INVOICES, GET_INVOICES_FAIL, GET_INVOICES_SUCCESS, INVOICE_INPUT_FIELD_CHANGE, UPDATE_INVOICE} from "actions/InvoiceTypes";

import {get} from 'lodash';

const INITIAL_STATE = {
    invoice_loading: true,
    invoice_error: false,

    headers: [
        {body: 'INVOICE ID', className: 'number'},
        {body: 'AUCTION ID', column_map: 'auction_id', className: 'number'},
        'DATE',
        {body: 'FACILITY', className: 'facility_name'},
        {body: 'UNIT', className: 'number'},
        {body: 'TOTAL', className: 'number'},
        {body: 'PAID', className: 'number'},
        {body: 'BALANCE', className: 'number'},
        'STATUS',
        false
    ],

    results: [],
    term: '',
    total_records: 0,

    show_loading: false,
    show_error: false,
    show: {

    },
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case INVOICE_INPUT_FIELD_CHANGE:

            switch (action.key) {
                case "term":
                    let term = action.value;
                    return {...state, term};
            }

            return {...state};

        case GET_INVOICES:
            return {...state, invoice_loading: true, total_records: 0, invoice_error: false, results: []};
        case GET_INVOICES_SUCCESS:
            return {...state, results: get(action, 'payload.invoices', []), total_records: get(action, 'payload.total_records', 0), invoice_loading: false, invoice_error: false};
        case GET_INVOICES_FAIL:
            return {...state, results: [], invoice_loading: false, invoice_error: get(action, 'payload')};

        case GET_INVOICE:
            if (action.reset === true){
                return {...state, show_loading: false, show_error: false, show: {}};
            }
            return {...state, show_loading: true, show_error: false, show: {}};
        case GET_INVOICE_SUCCESS:
            return {...state, show_loading: false, show: action.payload.invoice};
        case GET_INVOICE_FAIL:
            return {...state, show_loading: false, show_error: action.error};

        case UPDATE_INVOICE:
            const results = state.results.map(result => result.invoice_id == action.invoice.invoice_id ? action.invoice : result);

            return {...state, results};

        default:
            return state;
    }
}
