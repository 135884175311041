import React, { Fragment } from 'react';

const LoadingIndicator = ({ show = false, append = ' ' }) => (show ? (
  <>
    <i className="fa fa-spin fa-spinner" />
    {append}
  </>
) : null);

export default LoadingIndicator;
