import {applyMiddleware, compose, createStore} from "redux";
import reducers from "../reducers";
import thunkMiddleware from "redux-thunk";
import {customMiddleware} from './reduxMiddleware';

export default (initialState = {}) => {
    const composeEnhancers = (typeof window != 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) || compose;

    return createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(thunkMiddleware, customMiddleware))
    )
};
