import './styles.less'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Link from 'components/Link'
import { is_user_suspended, CANCEL_REASON_NON_PAYMENT } from 'helpers/user'
import PhoneLink from '../PhoneLink'
import HelpLink from '../HelpLink'

const UserStatusNotification = ({
    authed,
    payment_method,
    for_dashboard,
    with_container = true,
    reg_type: _reg_type
}) => {
    const [doRender, setDoRender] = useState(true)
    const [isGuest, setIsGuest] = useState(false)
    const [suspendedReason, setSuspendedReason] = useState(false)
    const reg_type = Number(_reg_type)

    useEffect(() => {
        setIsGuest(!authed)
        setSuspendedReason(is_user_suspended({ return_reason: true }))
        setDoRender(true)
    }, [authed])

    const renderPaymentMethodNotification = () => {
        const action = reg_type === 2 ? 'bidding' : 'posting an auction'
        return (
            <div className={`${!with_container ? 'container' : 'container-fluid'} notification-container`}>
                <div className={`alert alert-info ${for_dashboard ? 'dashboard-info' : ''}`} role="alert">
                    <span className="notice">Notice:</span>
                    {' '}
                    It seems we do not have updated payment information for you.
                    Please update your payment method in your
                    {' '}
                    <Link to="/account/settings" className="account-settings-link">Account Settings</Link>
                    {' '}
                    prior to {action}.
                </div>
            </div>
        )
    }

    if (!doRender || isGuest || (reg_type !== 2 && reg_type !== 4)) return null

    if (suspendedReason === false) {
        return payment_method === false ? renderPaymentMethodNotification() : null
    }

    if (suspendedReason == CANCEL_REASON_NON_PAYMENT) {
            return (
                <div className={`${!with_container ? 'container' : 'container-fluid'} notification-container`}>
                    <div className={`alert alert-danger ${for_dashboard ? 'dashboard-alert' : ''}`} role="alert">
                        <strong className="notice">Notice: Your account has been suspended due to a declined credit card on an auction that you won. </strong>
                        Pending resolution of this matter, your ability to submit bids and accept offers has been disabled. Where applicable, all your max bids have been lowered to current bids. Please either go to your account Settings page and click the Edit
                        Payment Method button to provide a new credit card, or otherwise ensure that your on-file method of payment has sufficient funds to cover your payments
                        due. Once you have done so, go to your Invoices list, click the Pay Now button for all Declined invoices, and then{' '}
                        <PhoneLink prepend="contact Customer Support at " append=" or "/> <HelpLink url="/requests/new">Submit a request</HelpLink>.
                    </div>
                </div>
            )
        }

        return (
            <div className={`${!with_container ? 'container' : 'container-fluid'} notification-container`}>
                <div className={`alert alert-danger ${for_dashboard ? 'dashboard-alert' : ''}`} role="alert">
                    <strong className="notice">Notice: Your account has been suspended.</strong>
                    {' '}
                    Your ability to submit bids and accept offers has been disabled. Where applicable, all your max bids have been lowered to current bids. Please <PhoneLink prepend="contact Customer Support at " append=" or "/> <HelpLink url="/requests/new">Submit a request</HelpLink>.
                </div>
            </div>
        )
}

const mapStateToProps = ({ user }) => {
    const {
        payment_method = false,
        reg_type = '1',
        authed = false,
        suspended = false
    } = user

    return { payment_method, reg_type, authed, suspended }
}

export default connect(mapStateToProps)(UserStatusNotification)
