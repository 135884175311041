import BaseInput from './BaseInput.jsx'
import { Field } from 'formik'

const Input = ({ formik, onChange, nested = false, forceValidation = false, ...props }) => {
    if (formik) {
        return <Field name={props.name}>
            {({field, form}) => {
                const fieldGroup = props?.name?.split('.')[0]
                const fieldName = props?.name?.split('.')[1]
                const touched = !nested ? form?.touched[props.name] : form?.touched?.[fieldGroup]?.[fieldName]
                const errorString = !nested ? form?.errors[props.name] : form?.errors?.[fieldGroup]?.[fieldName]
                
                return (
                    <BaseInput
                        error={touched ? errorString : forceValidation ? errorString : undefined}
                        {...props}
                        {...field}
                        onChange={(e) => {
                            field.onChange(e)
                            onChange && onChange({value: e.target.value, ...props}, e)
                        }}
                    />
                )
            }}
        </Field>
    }

    return (
        <BaseInput {...props} />
    )
}

export default Input