import { forwardRef } from 'react'
import { has } from 'lodash'
import { InputGroup, Form } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import HelpBlock from '../HelpBlock'
import 'styles/components/BaseInput.less'
const frontEnd = true

const Base = ({ children }) => <div className={frontEnd ? 'base-input-group' : 'input-group'}>{children}</div>
Base.Input = forwardRef((props, ref) => {
    const { mask } = props

    if (mask) {
        return <InputMask {...props}>
            {(inputProps) => frontEnd ? <input {...inputProps} ref={ref} /> : <Form.Control {...inputProps} custom ref={ref} />}
        </InputMask>
    }
    return frontEnd ? <input {...props} ref={ref} /> : <Form.Control custom {...props} ref={ref} />
})
Base.Addon = ({ children, textProps, ...props }) => frontEnd ? (
    <div {...props}>
        <span {...textProps}>
            {children}
        </span>
    </div>
) : (
    <InputGroup.Append {...props}>
        <InputGroup.Text {...textProps}>{children}</InputGroup.Text>
    </InputGroup.Append>
)

const Input = forwardRef((props, ref) => {
    const {
        type = 'text',
        name,
        className = 'form-control',
        outerClassReplace = 'form-group ',
        outerClassName = '',
        help = false,
        attributes: _attributes = [],
        label = '',
        labelAttrs = {},
        withIcon = false,
        wrap = true,
        required = false,
        error,
        value,
        onLblDoubleClick = undefined,
        feedbackClassName,
        onIconClick,
        step = "1",
        rightAddon = true,
        ...others
    } = props

    const { id = name } = others

    const handleOnChange = (e) => {
        if (props.onChange) {
            props.onChange(e)
        }
    }

    // Required to be set
    const attributes = {
        type,
        className,
        id,
        name,
        required,
        withIcon,
        value,
        error,
        step,
        ...others,
        ..._attributes
    }

    attributes['onChange'] = handleOnChange

    if (has(attributes, 'value.amount')) {
        attributes.value = attributes.value.amount
    }

    const { ...inputAttributes } = attributes

    inputAttributes['ref'] = ref
    inputAttributes['isInvalid'] = error

    // if (!inputAttributes.value) inputAttributes.value = ''

    if (type == 'email') {
        // For email input types we need to use a pattern
        inputAttributes.type = 'email'
    }

    const addonClass = frontEnd ? 'base-input-group-append' : ''

    const typeMap = {
        money: () => (
            <Base>
                <Base.Addon className={addonClass}>$</Base.Addon>
                <Base.Input min={0} {...inputAttributes} />
                {rightAddon && <Base.Addon className={addonClass}>.00</Base.Addon>}
            </Base>
        ),
        number: () => {
            return (
                <Base>
                    <Base.Addon className={addonClass}>$</Base.Addon>
                    <Base.Input min={0} {...inputAttributes} />
                    {rightAddon && <Base.Addon className={addonClass}>.00</Base.Addon>}
                </Base>
            )
        },
        percent: () => {
            return (
                <Base>
                    <Base.Input min="0" max="100" step={step} {...inputAttributes} />
                    <Base.Addon className={addonClass}>%</Base.Addon>
                </Base>
            )
        },
        date: () => {
            inputAttributes.placeholder = 'MM/DD/YYYY'

            return (
                <Base>
                    <Base.Input {...inputAttributes} />
                    {rightAddon &&
                    <Base.Addon className={addonClass}>
                        <i className="far fa-calendar-alt" />
                    </Base.Addon>}
                </Base>
            )
        },
        time: () => {
            inputAttributes.placeholder = 'HH:MM AM/PM'

            return (
                <Base>
                    <Base.Input {...inputAttributes} />
                    {rightAddon && <Base.Addon className={addonClass}>
                        <i className="fa fa-clock-o" />
                    </Base.Addon>}
                </Base>
            )
        },
        auctionDate: () => {
            inputAttributes.placeholder = 'MM/DD/YYYY'

            return (
                <Base>
                    <Base.Input {...inputAttributes} />
                    {rightAddon && <Base.Addon className={addonClass}>
                        <i className="far fa-calendar-alt" />
                    </Base.Addon>}
                </Base>
            )
        },
        auctionTime: () => {
            inputAttributes.placeholder = 'HH:MM AM/PM'

            return (
                <Base>
                    <Base.Input {...inputAttributes} />
                    {rightAddon && <Base.Addon className={addonClass}>
                        <i className="fa fa-clock-o" />
                    </Base.Addon>}
                </Base>
            )
        },
        search: () => {
            return (
                <Base>
                    <Base.Input {...inputAttributes} error={error} />
                    {rightAddon &&
                    <Base.Addon textProps={{className: 'p-0 border-0'}}>
                        <button
                            type="submit"
                            className="btn btn-regular btn-blue btn-search"
                            onClick={onIconClick && onIconClick}
                        >
                            <i className="fa fa-search fa-lg" />
                        </button>
                    </Base.Addon>}
                </Base>
            )
        },
        protocol: () => {
            return (
                <Base>
                    <Base.Addon textProps={{ className: 'p-0 border-0' }} className={frontEnd ? 'addon-select' : ''}>
                        {frontEnd ? (
                            <select
                                size="sm"
                                name="protocol"
                                className="form-control"
                                onChange={handleOnChange}
                            >
                                <option value="http://">http://</option>
                                <option value="https://">https://</option>
                            </select>
                        ) : (
                            <Base.Input 
                                as="select"
                                size="sm"
                                name="protocol"
                                className="form-control"
                                onChange={handleOnChange}
                            >
                                <option value="http://">http://</option>
                                <option value="https://">https://</option>
                            </Base.Input>
                        )}
                    </Base.Addon>
                    <Base.Input {...inputAttributes} />
                </Base>
            )
        },
        eye: () => {
            return (
                <Base>
                    <Base.Input {...inputAttributes} />
                    {rightAddon &&
                    <Base.Addon className={addonClass}>
                        <i
                            onClick={props.showHide}
                            className={props.show ? 'fas fa-eye-slash' : 'fas fa-eye'}
                        />
                    </Base.Addon>}
                </Base>
            )
        },
        default: () => <Base.Input {...inputAttributes} />
    }

    return !wrap ? <Base.Input {...inputAttributes} /> : (
        <div className={`${frontEnd && 'base-input-div'} ${outerClassReplace} ${outerClassName} ${error && frontEnd && 'parsley-has-error'}`}>
            <label 
                htmlFor={attributes.id}
                className="control-label" {...labelAttrs}
                onDoubleClick={onLblDoubleClick}
            >
                {label}
            </label>
            {typeMap[withIcon || 'default']()}
            <HelpBlock help={help} />
            {frontEnd ? (
                error && <p className='parsley-errors-list filled'>{error}</p>
            ) : (
                <Form.Control.Feedback type="is-invalid" className={feedbackClassName}>
                    {error}
                </Form.Control.Feedback>
            )}
        </div>
    )
})

export default Input