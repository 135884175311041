import {GET_REPORTS, GET_REPORTS_FAIL, GET_REPORTS_SUCCESS} from "actions/ReportsTypes";

const INITIAL_STATE = {
    loading: false,
    error: null,

    total_records: 0,
    results: [],
};

export default (state = INITIAL_STATE, action) => {

    const {error = null} = action;

    switch (action.type) {

        case GET_REPORTS:
            return {...state, loading: true, error: null, total_records: 0};
        case GET_REPORTS_SUCCESS:
            const {reports: results = [], total_records = 0} = action.payload || {};

            return {...state, loading: false, results, total_records};
        case GET_REPORTS_FAIL:
            return {...state, loading: false, error};

        default:
            return state;
    }
}
