import BaseService from "./BaseService";
import { environment } from './environment';
import { GET } from "./RequestMethods";

class UserIpService extends BaseService {
    async getUserIp(req) {
        let user_ip = null;

        if (req) {
            // fetch user IP from server side
            user_ip = req.headers['x-forwarded-for']?.split(',')[0] ||
                req.connection.remoteAddress ||
                req?.socket?.remoteAddress ||
                req?.connection?.socket?.remoteAddress ||
                null;
        } else {
            try {
                const data = await this.submitRequestWithPromise(GET, environment.apiEndPoint, 'users/check/user-ip');
                user_ip = data.split(',')[0] || null;

                if (user_ip && window?.localStorage?.getItem('user_ip') !== user_ip) {
                    window.localStorage.setItem('user_ip', user_ip);
                }
            } catch (error) {
                console.error('Failed to fetch IP address', error);
            }
        }

        return user_ip;
    }
}

export default new UserIpService();
