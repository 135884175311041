import React, { Component } from 'react'
import { APP_CONFIG } from '../Config'
import 'styles/components/classic-switch.less'
import HelpLink from "./HelpLink";

class SwitchClassic extends Component {
    state = {
        classic_url: 'https://storagetreasures.com',
    }

    componentDidMount = () => {
        const classic_url = APP_CONFIG.REACT_APP_CLASSIC_SITE_URL

        if (classic_url) {
            this.setState({ classic_url })
        }
    }

    mapPathname = () => {
        const { pathname = '' } = window.location || {}

        switch (pathname) {
            case '/account/dashboard':
                return '/account'
            case '/pro-membership-upgrade':
                return '/info-vault/general-information/pro-membership-upgrade'
            case '/account/auctions/canceled':
                return '/account/auctions/cancelled'
            case '/account/auctions/drafts':
                return '/account/auctions/draft'
            case '/account/auctions/drafts/post':
                return '/auctions/post'
            default:
                return pathname
        }
    }

    navTo = () => {
        const { classic_url } = this.state
        const pathname = this.mapPathname()
        window.location = `${classic_url}${pathname}`
    }

    renderTopBar = () => (
        <div className="top-switch-bar">
            <div className="top-switch-bar-background" />
            <div className="full-width">
                <div className="switch-half left instruction">
                    <div>Welcome to the New StorageTreasures!</div>
                    <div className="ie-text">
                        <i className="fa fa-internet-explorer"/> For a richer experience, upgrade to <a href="https://www.google.com/chrome/" rel="nofollow noopener" target="_blank">Google Chrome </a>
                        or <a href="https://www.mozilla.org/en-US/firefox/new/?redirect_source=getfirefox-com/" rel="nofollow noopener" target="_blank">Firefox</a>. Learn more about
                        our <HelpLink url="/articles/360045729833-Browser-Support" id="navbar-help-center">browser support</HelpLink>.
                    </div>
                </div>
                <div className="switch-half right try-it-now-wrapper">
                    <a onClick={this.navTo} href="#" id="switch-to-classic" className="btn try-it-now">
                        SWITCH TO CLASSIC
                    </a>
                </div>
            </div>
        </div>
    )

    renderSidebar = () => (
        <div className="sidebar-switch-bar">
            <div className="sidebar-switch-bar-background" />
            <div className="try-it-now-wrapper">
                <a onClick={this.navTo} href="#" id="sidebar-try-it-now" className="btn try-it-now">
                    SWITCH TO CLASSIC
                </a>
            </div>
        </div>
    )

    render() {
        const { sidebar } = this.props

        return sidebar ? this.renderSidebar() : this.renderTopBar()
    }
}

SwitchClassic.defaultProps = {
    sidebar: false
}

export default SwitchClassic
