// general facility types
export const GET_FACILITY = "get_facility";
export const GET_FACILITY_SUCCESS = "get_facility_success";
export const GET_FACILITIES_FAIL = "get_facilities_fail";
export const BOOKMARK_FACILITY = "bookmark_facility";
export const BOOKMARK_FACILITY_SUCCESS = "bookmark_facility_success";
export const BOOKMARK_FACILITY_FAIL = "bookmark_facility_fail";

export const GET_FACILITY_ONLINE_AUCTIONS = "get_facility_online_auctions";
export const GET_FACILITY_ONLINE_AUCTIONS_SUCCESS = "get_facility_online_auctions_success";
export const GET_FACILITY_ONLINE_AUCTIONS_FAIL = "get_facility_online_auctions_failed";

export const GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON = "get_facility_online_auctions_coming_soon";
export const GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_SUCCESS = "get_facility_online_auctions_coming_success";
export const GET_FACILITY_ONLINE_AUCTIONS_COMING_SOON_FAIL = "get_facility_online_auctions_coming_fail";

export const GET_FACILITY_LIVE_AUCTIONS = "get_facility_live_auctions";
export const GET_FACILITY_LIVE_AUCTIONS_SUCCESS = "get_facility_live_auctions_success";
export const GET_FACILITY_LIVE_AUCTIONS_FAIL = "get_facility_live_auctions_failed";

export const GET_FACILITY_PMS = "get_facility_pms";

export const SET_FACILITY_EDIT_FORM_DATA = "set_facility_edit_form_data";

export const GET_USER_FACILITIES = "get_user_facilities";
export const GET_USER_FACILITIES_SUCCESS = "get_user_facilities_success";
export const GET_USER_FACILITIES_FAIL = "get_user_facilities_fail";

export const GET_USER_FACILITY = "get_user_facility";
export const GET_USER_FACILITY_SUCCESS = "get_user_facility_success";
export const GET_USER_FACILITY_FAIL = "get_user_facility_fail";

export const FACILITY_LOAD_FEATURES = "facility_load_features";
export const FACILITY_LOAD_FEATURES_SUCCESS = "facility_load_features_success";
export const FACILITY_LOAD_FEATURES_FAIL = "facility_load_features_fail";

export const FACILITY_SAVE = "facility_save";
export const FACILITY_SAVE_SUCCESS = "facility_save_success";
export const FACILITY_SAVE_FAIL = "facility_save_fail";
