import Router from 'next/router';
import { useEffect } from 'react';

/**
 * Server side redirects
 * @param Location
 * @param res
 * @param redirect_status
 * @returns {boolean}
 */
export const handleRedirect = (Location, res = undefined, redirect_status = 302) => {
    if (res && res.writeHead) {
        res.writeHead(redirect_status, { Location });
        res.end();

        return true;
    }
};

/**
 * client side redirects
 * @return {null}
 */
const DoRedirect = function ({ url = false, to = '/' }) {
    useEffect(() => {
        Router.replace(url || to).then((r) => null);
    });

    return null;
};

export default DoRedirect;
