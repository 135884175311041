export const general_toastr_message = ({
  title = false, message, type = 'info', options = undefined,
}) => {
  try {
    return typeof window !== 'undefined' && window.toastr[type](message, title, options);
    }catch (e) {

    }

    return null;
};

export const clear_toastr = (reference) => {
    try {
        return reference && typeof window != 'undefined' && window.toastr.clear(reference);
  } catch (e) {

  }

    return null;
};

export const clear_toastrs = () => {
    try {
        return typeof window != 'undefined' && window.toastr.clear();
    }catch (e) {

    }

    return null;
};

export const general_error_keep_open_toastr = ({message}, onHidden = undefined) => {
    return general_error_toastr({message, options: {extendedTimeOut: 0, timeOut: 0, onHidden}});
};

export const general_error_toastr = ({
  title = 'Oops!', message = 'An error occurred. Please try again!', type = 'error', options = undefined,
} = {}) => {
  return general_toastr_message({
    title, message, type, options,
  });
};

export const general_form_error_toastr = ({
  title = 'Uh Oh.', message = 'Please make sure to fill out all of the required fields marked in red.', type = 'error', options = undefined,
} = {}) => {
  return general_toastr_message({
    title, message, type, options,
  });
};

export const general_success_toastr = ({
  title = undefined, type = 'success', message = '', options = {}, ...others
}) => {
  return general_toastr_message({
    title, message, type, options: { ...options, ...others },
  });
};

export const file_size_toastr = (file_name, file_size) => {
    return general_toastr_message({
    title: 'Oops!',
    message: `Maximum size limit error for "${file_name}". The maximum size limit is ${file_size}.`,
    type: 'error',
  });
};

export const unsupported_file_type_toastr = (file_name, file_type, { types = { image: 'jpg/jpeg, gif, and png', pdf: 'pdf' } } = {}) => {
  const message = file_type == 'image'
    ? `Unsupported format error for "${file_name}". <br /> Valid file types are: ${types[file_type]}.`
    : `Unsupported format error for "${file_name}". A PDF file is required.`;

    return general_toastr_message({
    title: 'Oops!',
    message,
    type: 'error',
  });
};
