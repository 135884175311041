import {THINGAMABOB_CLEAR_ACTIONS, THINGAMABOB_SET_ACTIONS, THINGAMABOB_SET_PROP} from "actions/ThingamabobTypes";

const INITIAL_STATE = {
    loading: false,
    error: null,
    show: 'closed',
    position: 'left',
    tab: 'tab-thinamabob-user',
    actions: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case THINGAMABOB_SET_PROP:
            return {...state, [action.name]: action.value};
        case THINGAMABOB_SET_ACTIONS:
            return {...state, actions: action.actions, loading: true};
        case THINGAMABOB_CLEAR_ACTIONS:
            return {...state, actions: [], loading: true};
        default:
            return state;
    }
}
