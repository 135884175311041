import {FORM_REQUEST_CSRF_TOKEN, FORM_SAVING_DONE, FORM_SAVING_START} from "actions/FormTypes";

const INITIAL_STATE = {
    error: null,
    saving: false,
    csrf_token: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FORM_SAVING_START:
            return {...state, saving: true};
        case FORM_SAVING_DONE:
            return {...state, saving: false};
        case FORM_REQUEST_CSRF_TOKEN:
            const {csrf_token} = action;

            return {...state, csrf_token};
        default:
            return state;
    }
}
