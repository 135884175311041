// general offer types
export const GET_OFFER = 'get_offer';
export const GET_OFFER_SUCCESS = 'get_offer_success';
export const GET_OFFER_FAIL = 'get_offer_fail';

export const UPDATE_OFFER = 'update_offer';
export const UPDATE_OFFER_SUCCESS = 'update_offer_success';
export const UPDATE_OFFER_FAIL = 'update_offer_fail';

export const OFFER_STATUS_DEFAULT = '1';
export const OFFER_STATUS_ACCEPTED = '2';
export const OFFER_STATUS_REJECTED = '3';
export const OFFER_STATUS_EXPIRED = '4';
