import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import LayoutHeader from 'layouts/LayoutHeader'
import Sidebar from 'layouts/Sidebar'
import Footer from 'layouts/Footer'
import PageTitle from 'components/partials/PageTitle'
import Thingamabob from 'components/Dev/Thingamabob'
import { APP_CONFIG } from 'Config'
import { get } from 'lodash'
import { screenResize, setPageContainerClass } from 'actions'
import UserStatusNotification from 'components/UserStatusNotification'
import SwitchClassic from 'components/SwitchClassic'
import UserPageTitle from 'components/partials/UserPageTitle'
import LivePerson from './LivePerson'
import SMSOptInAlert from '../components/SMSOptInAlert'
import 'styles/Main.less'
import 'styles/common.less'
import 'styles/vendors/toastr.less'

const OtherLayout = (props) => {
    const [elementPosFixed, setElementPosFixed] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            props.setPageContainerClass({
                containerClass: `container${window.innerWidth <= 1200 ? '-fluid' : ''}`
            })
            props.screenResize({ width: window.innerWidth })
        }

        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const renderResponsiveTitleArea = () => {
        if (!props.pageAction) {
            return null
        }

        return (
            <div className={`${props.containerClass} mobile-pageTitle`}>
                <div className="dashboard-page-title">
                    <div className="dashboard-page-title-text">
                        {props.pageAction}
                    </div>
                </div>
            </div>
        )
    }

    const Header = () => {
        if (props.subNav) {
            return (
                <LayoutHeader authed={props.authed} noTitle showTopMessageBar={props.showTopMessageBar}>
                    <PageTitle />
                    {props.subNav}
                </LayoutHeader>
            )
        }
        return <LayoutHeader authed={props.authed} />
    }

    const thingamabob = get(APP_CONFIG, 'DEV', false) ? <Thingamabob /> : null
    const notificationWithContainer = typeof props.notification_container !== 'undefined'
        ? props.notification_container
        : props.with_container

    const maintenanceClasses = APP_CONFIG.REACT_APP_MAINTENANCE_NOTICE && props.subNav
        ? 'pageContentMaintenance myAccountContentMaintenance'
        : 'pageContentMaintenance'

    return (
        <div>
            <div className="content-container" id="content-container">
                <Sidebar showTopMessageBar={props.showTopMessageBar} />
                {props.showTopMessageBar && (
                    <div className={`top-switch-bar-top is-${elementPosFixed ? 'fixed' : 'relative'}`}>
                        <SwitchClassic />
                    </div>
                )}
                <Header />
                <div className={`pageContent ${props.subNav && 'myAccountContent'} ${maintenanceClasses}`}>
                    {props.subNav ? <UserPageTitle fm_dashboard={get(props, 'fm_dashboard', false)} /> : <PageTitle />}
                    {renderResponsiveTitleArea()}
                    {props.show_notification_area &&
                        <SMSOptInAlert with_container={notificationWithContainer} />}
                    {props.show_notification_area &&
                        <UserStatusNotification with_container={notificationWithContainer} />}
                    <div className="clearfix" />
                    <div className={(props.pageAction ? 'with-mobile-page-action ' : '') + (props.with_container ? props.containerClass : '')}>
                        <div className={props.with_container ? props.containerClass : ''}>
                            {props.children}
                        </div>
                    </div>
                </div>
                {thingamabob}
                <Footer add_bottom_top={props.add_bottom_top} />
            </div>
            <LivePerson />
        </div>
    )
}

OtherLayout.defaultProps = {
    with_container: true,
    add_bottom_top: true,
    show_notification_area: false,
    showTopMessageBar: false,
    subNav: null
}

const mapStateToProps = ({ page, user }) => {
    const { pageAction, containerClass } = page.data
    const { reg_type: regType, user_id: userId } = user

    return { containerClass, pageAction, regType, userId }
}

export default connect(mapStateToProps, { setPageContainerClass, screenResize })(OtherLayout)
