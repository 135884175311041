import React, { useMemo, useEffect } from 'react'
import useTimer from 'hooks/useTimer'
import './index.less'

const CountdownTimer = ({ 
    from, 
    upcoming, 
    auctionExpired = false, 
    current, 
    handleExpiredTime = () => {},
    bidManager = false, 
    auctionTile = false
}) => {
    const [{ time, expired }] = useTimer({
        from,
        upcoming,
        current
    })

    const label = useMemo(() => {
        return upcoming ? 'Starts in' : 'Closes in'
    }, [upcoming])

    const isBlackText = (unit) => {
        if (unit === 'days' && time.days === '00' && expired.years) return true
        if (unit === 'hours' && time.hours === '00' && expired.days) return true
        if (unit === 'minutes' && time.minutes === '00' && expired.hours) return true
        if (unit === 'seconds' && time.seconds === '00' && expired.minutes) return true
        return false
    }

    useEffect(() => {
        if (expired.expired) {
            handleExpiredTime(true)
        }
    }, [expired])
    
    return expired.expired || auctionExpired ? (
        <div id="countdown-timer-expired" className={`countdown-container expired-timer bordered-countdown ${auctionTile ? 'auction-tile-countdown' : ''}`}>
            <div className="timer-title">{label}:</div>
            <div className="expired-text">Expired</div>
        </div>
    ) : (
        <div className={`bordered-countdown ${bidManager ? 'bid-manager-countdown' : ''}  ${auctionTile ? 'auction-tile-countdown' : ''}`}>
            <div className="timer-title">{label}:</div>
            <div className="timer-container">
                <div className={`countdown-timer-col border-right countdown-timer-text ${isBlackText('days') && 'black-text'}`}>
                    <h2>{time.days}</h2>
                    D
                </div>
                <div className={`countdown-timer-col border-right countdown-timer-text ${isBlackText('hours') && 'black-text'}`}>
                    <h2>{time.hours}</h2>
                    H
                </div>
                <div className={`countdown-timer-col border-right countdown-timer-text ${isBlackText('minutes') && 'black-text'}`}>
                    <h2>{time.minutes}</h2>
                    M
                </div>
                <div className={`countdown-timer-col countdown-timer-text ${isBlackText('seconds') && 'black-text'}`}>
                    <h2>{time.seconds}</h2>
                    S
                </div>
            </div>
        </div>
    )
}

export default CountdownTimer