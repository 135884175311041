import BaseService from './BaseService';
import { environment } from './environment';
import { GET, PUT } from './RequestMethods';

class OfferService extends BaseService {

    index = (params) => (
        this.submitRequestWithPromise(GET, environment.apiEndPoint, 'users/account/offers', params)
    );

    /**
     * accept or reject active offer.
     * @param offer_id
     * @returns {*}
     */

    get = (offer_id) => {
        return this.submitRequestWithPromise(GET, environment.apiEndPoint, `users/account/offers/${offer_id}`);
    };

    update = (offer_id, case_id, action) => {
        const params = { offer: { offer_id, case_id, action } };

        return this.submitRequestWithPromise(PUT, environment.apiEndPoint, `users/account/offers/${offer_id}`, params);
    };

    getOffers = () => (
        this.submitRequestWithPromise(GET, environment.apiEndPoint, 'users/account/offers')
    );

}

export default new OfferService();
