import React, { Component } from 'react'
import { connect } from 'react-redux'
import NavLink from 'components/Page/NavLink'
import Logout from 'components/UserAccount/Logout'
import {Html5Entities as base64} from "html-entities";
import HelpLink from "../components/HelpLink";
import MaintenanceNotice from 'layouts/MaintenanceNotice'
const LOGO = '/static/images/STLogoLarge.svg'
const LOGO_SMALL = '/static/images/STLogoSmall.svg'

class LayoutHeader extends Component {
    state = {
        headerButtonSizes: '',
        containers: 'container',
        affix: false,
        affixOffset: 53
    }

    componentDidMount = () => {
        this.getSizes()

        window.addEventListener('resize', this.getSizes)
        window.addEventListener('scroll', this.eventWindowScroll)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.getSizes)
        window.removeEventListener('scroll', this.eventWindowScroll)
    }

    eventWindowScroll = () => {
        if (this.props.showTopMessageBar) {
            this.setState({ affix: window.scrollY > this.state.affixOffset })
        }
    }

    getSizes = () => {
        if (typeof window === 'undefined') {
            return false
        }

        this.setState({
            headerButtonSizes: window.innerWidth < 768 ? 'btn-sm' : 'btn-regular',
            containers: window.innerWidth < 1200 ? 'container-fluid' : 'container',
        })
    }

    renderPostAuction = () => {
        const { reg_type } = this.props

        if (reg_type !== '1' && reg_type !== '4') { // reg type 1 = Facility manager and reg type 4 = Private seller
            return null
        }

        return (
            <li className="dropdown" style={{ flexGrow: 1, textAlign: 'center' }}>
                <a id="top-nav-post-auctions-dropdown" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    Post Auction
                    <span className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    <li>
                        <NavLink id="top-nav-post-online-auction" to="/account/auctions/drafts/post">Online Auction</NavLink>
                        {reg_type !== '4' && <NavLink id="top-nav-post-live-auction" to="/account/live-auctions/post">Live Auction</NavLink>}
                    </li>
                </ul>
            </li>
        )
    }



    render() {
        const {
            authed, loginLinkRedirect, children, noTitle,
        } = this.props

        const guest = !authed

        const affixClass = this.state.affix ? 'affix ' : ""

        return (
            <>
                <div className={`navbar affix-top navbar-fixed-top1 ${this.props.showTopMessageBar ? affixClass : ''}`}>
                <MaintenanceNotice />
                    <nav className="navbar nav-upper">
                        <div className={this.state.containers}>
                            <ul className="nav navbar-upper left">
                                <li>
                                    <NavLink to="/" className="navbar-logo" >
                                        <img src={this.props.windowWidth <= 991 ? LOGO_SMALL : LOGO} alt="StorageTreasures Logo" />
                                    </NavLink>
                                </li>
                            </ul>
                            <div className="header-buttons-container">
                                <div className="header-buttons">
                                    {guest ? (
                                        <>
                                            <NavLink to="/register" id="header-sign-up" className={`btn btn-orange header-register ${ this.state.headerButtonSizes}`}>Sign Up</NavLink>
                                            <NavLink to={loginLinkRedirect} id="header-log-in" className={`btn btn-blue header-login ${ this.state.headerButtonSizes}`}>Log In</NavLink>
                                        </>
                                    ) : ''}
                                    {authed ? (
                                        <>
                                            <NavLink to="/account/dashboard" id="header-my-account" className={`btn btn-orange header-register authed ${ this.state.headerButtonSizes}`}>My Account</NavLink>
                                            <Logout className={`btn btn-blue header-login ${this.state.headerButtonSizes}`} />
                                        </>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    </nav>

                    <nav className="navbar navbar-static-top navbar-default navbar-lower hidden-xxs hidden-xs hidden-sm">

                        <div className={this.state.containers}>

                            <div className="navbar" id="navbar">
                                <ul className="nav top-navbar navbar-nav" style={{ fontSize: '16px', display: 'flex', width: '100%' }}>
                                    <li style={{ flexGrow: 2, textAlign: 'center' }}>
                                        <NavLink
                                            id="top-nav-online-storage-auctions"
                                            to="/auctions"
                                            onClick={() => {
                                                window.localStorage.removeItem('search_obj')
                                            }}
                                        >
                                            Online Storage Auctions
                                        </NavLink>
                                    </li>
                                    <li style={{ flexGrow: 2, textAlign: 'center' }}>
                                        <NavLink
                                            id="top-nav-live-storage-auctions"
                                            to="/live-auctions"
                                            onClick={() => {
                                                window.localStorage.removeItem('live_auctions_search_obj')
                                            }}
                                        >
                                            Live Storage Auctions
                                        </NavLink>
                                    </li>
                                    {this.renderPostAuction()}
                                    <li id="navbar-dropdown-pro-community" className="dropdown" style={{ flexGrow: 1, textAlign: 'center' }}>
                                        <a
                                            id="top-nav-pro-community"
                                            className="dropdown-toggle"
                                            data-toggle="dropdown"
                                            role="button"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Pro Community
                                            <span className="fa fa-chevron-down"/>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a
                                                    id="top-nav-auction-pro-blog"
                                                    href="http://blog.storagetreasures.com"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                        Auction Pro Blog
                                                </a>
                                            </li>
                                            <li>
                                                <NavLink id="top-nav-upgrade-to-pro" to="/pro-membership-upgrade">
                                                    Upgrade to Pro
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink id="top-nav-auction-app" to="/online-storage-auction-app">
                                                    StorageTreasures App
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="dropdown" style={{ flexGrow: 1, textAlign: 'center' }}>
                                        <a
                                            id="navbar-dropdown-about"
                                            className="dropdown-toggle"
                                            data-toggle="dropdown"
                                            role="button"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            About Us
                                            <span className="fa fa-chevron-down" />
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <NavLink id="navbar-about" to="/about">StorageTreasures</NavLink>
                                            </li>
                                            <li>
                                                <a
                                                    id="navbar-about-opentech"
                                                    href="https://www.opentechalliance.com/about-us/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    OpenTech Alliance
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    id="navbar-about-charity-storage"
                                                    href="https://www.charitystorage.org"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Charity Storage
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li style={{ flexGrow: 1, textAlign: 'center' }}>
                                        <HelpLink showSupportEmail={false} id="navbar-help-center">
                                            Help Center
                                        </HelpLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {children && children.map((e, index) => React.cloneElement(e, { noTitle, key: e.key || `subnav-item-${index}` }))}
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ user, page }) => {
    const {
        authed = false,
        reg_type = false
    } = user

    const {
        loginLinkRedirect
    } = page.data || {}

    const {
        windowWidth
    } = page

    return {
        authed,
        reg_type,
        loginLinkRedirect,
        windowWidth
    }
}

export default connect(mapStateToProps)(LayoutHeader)
