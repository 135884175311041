import React from 'react';
import LoadingIndicator from './LoadingIndicator';

const BtnDropdown = ({
  children, id, label = 'Actions', wrapperClassAppend = '', buttonWrapperClass = '', show_loading = false,
}) => (
  <div className={`text-left dropdown btn-group ${wrapperClassAppend}`}>
    <div className={buttonWrapperClass}>
      <button className="btn btn-default dropdown-toggle auction-actions-dropdown button-dropdown" type="button" id={id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        <LoadingIndicator show={show_loading} />
        {label}
        <span className="fa fa-chevron-down" />
      </button>
      <ul className="dropdown-menu auction-actions-menu button-dropdown-menu" aria-labelledby={id}>{children}</ul>
    </div>
  </div>
);
export default BtnDropdown;
