import React, { Component } from 'react';

const Question = '/static/images/question.svg';

class MissingBlock extends Component {
    classShow = () => {
        const { show = true } = this.props;

        let showLabel = ' hidden';
        if (show === true) {
            showLabel = ' in';
        }

        return showLabel;
    };

    render() {
        const {
            id = 'table-missing-results',
            image = Question,
            imageClass = 'standard',
            message = 'Nothing here. Check back soon!',
        } = this.props;

        const showClass = this.classShow();

        return (
            <div id={id} className={`missing-block row fade ${showClass}`}>
                <div className="text-center marginT30 marginB30">
                    <img src={image} alt="Empty"
                         className={` ${imageClass} marginB30 missing-image`}
                         style={{ minHeight: 91 }}/>

                    <div className="st-h3">{message}</div>
                </div>
            </div>
        )
    }
}

MissingBlock.defaultProps = {
    id: undefined,
};

export default MissingBlock;
