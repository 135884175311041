import React from 'react'
import Footer from 'layouts/Footer'
import './styles.less'
import MaintenanceNotice from 'layouts/MaintenanceNotice'

const LOGO = '/static/images/STLogoLarge.svg'
const PIRATE_SHIP = '/static/images/pirate-ship.svg'

const Error503 = () => {
    return (
        <div className="maintenance-page-container">
            <MaintenanceNotice />
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12 mt-3">
                        <img
                            src={LOGO}
                            alt="StorageTreasures Logo"
                            className="nav-bar-logo cursor-pointer"
                        />
                    </div>
                    <div className="col col-xs-12 error-page text-center">
                        <img src={PIRATE_SHIP} alt="Pirate Ship" className="error-hero" />
                        <div className="text">
                            <h1 className="mt-3 error-header text-center">
                                Maintenance <br/> In Progress
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <Footer showRegisterNow={false} showLinks={false} showCopyRight={false} add_bottom_top />
        </div>
    )
}

export default Error503
