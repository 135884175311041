import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserSubNavigation from 'layouts/UserSubNavigation';

class FacilityManagerNavigation extends Component {
    render() {
        const { has_imports, reg_type } = this.props;

        // TODO: Update routes
        const navigation = [
            {
                name: 'Dashboard',
                id: 'dashboard',
                link: '/account/dashboard',
                className: 'dashboard-menu-item',
                enum: 9,
                sub: [],
            },
            {
                name: 'Settings',
                id: 'settings',
                link: '/account/settings',
                className: 'dashboard-menu-item',
                enum: 10,
                sub: [],
            },
            {
                name: 'Facility Mgmt',
                id: 'facility-mgmt',
                link: '/account/facilities',
                className: 'dashboard-menu-item',
                enum: 11,
                sub: [],
            },
            {
                name: 'Online Auctions',
                id: 'online-auctions',
                link: '',
                className: 'dropdown dashboard-dropdown dashboard-menu-item',
                enum: [12, 13, 14, 15, 16, 17, 18, 100],
                sub: [
                    {
                        name: 'Active Auctions',
                        id: 'active-auctions',
                        link: '/account/auctions/active',
                        enum: 12,
                        sub: [],
                    },
                    {
                        name: 'Sold Auctions',
                        id: 'sold-auctions',
                        link: '/account/auctions/sold',
                        enum: 13,
                        sub: [],
                    },
                    {
                        name: 'Unsold Auctions',
                        id: 'unsold-auctions',
                        link: '/account/auctions/unsold',
                        enum: 14,
                        sub: [],
                    },
                    {
                        name: 'Canceled Auctions',
                        id: 'canceled-auctions',
                        link: '/account/auctions/canceled',
                        enum: 15,
                        sub: [],
                    },
                    {
                        name: 'Canceled After Sold',
                        id: 'canceled-after-sold',
                        link: '/account/auctions/cancel-after-sold',
                        enum: 16,
                        sub: [],
                    },
                    {
                        name: 'Draft Auctions',
                        id: 'draft-auctions',
                        link: '/account/auctions/drafts',
                        enum: 17,
                        sub: [],
                    },
                    has_imports && {
                        name: 'Imported Auctions',
                        id: 'imported-auctions',
                        link: '/account/auctions/imported',
                        enum: 18,
                        sub: [],
                    },
                ],
            },
            ...(reg_type !== '4' ? [{
                name: 'Live Auctions',
                id: 'live-auctions',
                link: '/account/live-auctions',
                className: 'dashboard-menu-item',
                enum: 19,
                sub: [],
            }] : []),
            {
                name: 'Invoicing',
                id: 'invoicing',
                link: '',
                className: 'dashboard-menu-item',
                enum: [23, 24],
                sub: [
                    {
                        name: 'Invoices',
                        id: 'invoices',
                        link: '/account/invoices',
                        enum: 23,
                        sub: [],
                    },
                    {
                        name: 'Case Mgmt',
                        id: 'case-mgmt',
                        link: '/account/cases',
                        enum: 24,
                        sub: [],
                    },
                ],
            },
            {
                name: 'Reports',
                id: 'reports',
                link: '/account/reports',
                className: 'dashboard-menu-item',
                enum: 21,
                sub: [],
            },
            {
                name: 'Training',
                id: 'training',
                link: '/account/training',
                className: 'dashboard-menu-item',
                enum: 22,
                sub: [],
            },
        ];

        return <UserSubNavigation items={navigation} collapse_at_sm fm_dashboard
                                  noTitle={this.props.noTitle}/>;
    }
}

const mapStateToProps = ({ page, user: { has_imports } }) => ({
    has_imports,
});

export default connect(mapStateToProps)(FacilityManagerNavigation);
