export const objCleanup = (obj, strict = true, {filterOut = []} = {}) => {
    for (const propName in obj) {
        if ((strict == false && !obj[propName]) || obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }

        if(filterOut.includes(propName)){
            delete obj[propName];
        }
    }
    return obj
}
