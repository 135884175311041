import React from 'react';
import { AB, AUTHED, FAILS_ACCOUNT, FAILS_AUTH, FAILS_GUEST, FM, GUEST, ROLES } from '../Enums/middleware';

export default (Component, { user: { reg_type = false, authed = false } = {} }) => {

    let middleware = Component.middleware || [];

    // Make sure that middleware is always an array. Sometimes it could just be a string
    if (!Array.isArray(middleware)) {
        middleware = [middleware];
    }

    if (!middleware || middleware.length == 0) {
        return true;
    }

    // if middleware.length > 0
    if (middleware.some(a => [AB, FM].includes(a))) {
        if (!authed) {
            return FAILS_AUTH;
        }

        if (!middleware.includes(reg_type)) {
            return FAILS_ACCOUNT;
        }

        return true;
    }

    if (middleware.includes(GUEST)) {
        return authed ? FAILS_GUEST : true;
    }

    if (middleware.includes(AUTHED)) {
        return !authed ? FAILS_AUTH : true;
    }

    if (!authed) {
        return FAILS_AUTH;
    }

    return middleware.some(m => ROLES.indexOf(m) >= 0);
};
