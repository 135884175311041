import { Modal } from 'react-bootstrap'
import SubmitCancelButtons from '../partials/SubmitCancelButtons'

const ConfirmationModal = ({
    show,
    onHide,
    loading,
    handleFormSubmit,
    handleCancelForm
}) => {
    return (
        <Modal
            size="md"
            onHide={onHide}
            show={show}
            hasClose
        >
            <Modal.Header closeButton>
                <Modal.Title>Update Membership</Modal.Title>
            </Modal.Header>
            <Modal.Body className="manage-membership-modal_body">
                Are you sure you want to update your membership?
                <SubmitCancelButtons
                    show_loading={true}
                    loading={loading}
                    saving={loading}
                    submitLabel="Update"
                    cancelConfirmation={false}
                    handleCancelForm={handleCancelForm}
                    onClick={handleFormSubmit}
                />
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal