import React from 'react';
import {setQueryString} from "./page";
import { get_bid_message } from 'reducers/AuctionReducer'

export const generalRedoSearch = async ({callback, date_filters, search_obj, sort_direction, currentPage, sortColumn, page_count}) => {

    const presets = date_filters.map(df => df.date);

    const {
        term,
        search_date_preset = 'any',
        search_date_start,
        search_date_end,
    } = search_obj;

    const options = {
        page_count, // results per page
        page_num: currentPage, // which page in pagination
        sort_direction,
    };

    if (search_obj.page_num && search_obj.page_num == 1) {
        delete search_obj['page_num'];
    }
    // if (search_obj.term && search_obj.term.length == 0) {
    //     delete search_obj['term'];
    // }

    setQueryString({search_obj});

    if (search_date_preset) options.search_date_preset = search_date_preset;
    if (search_date_start) options.search_date_start = search_date_start;
    if (search_date_end) options.search_date_end = search_date_end;

    if (presets.includes(search_date_preset)) {
        options.sort_column = sortColumn.column_map;
        return await callback(term, options)
    }

    return await callback(term, options);
};

export const formatLienStatus = ({
    isArsEnabled = false,
    isPast = true,
    ars
}) => {
    if (!isArsEnabled) {
        return 'N/A'
    }

    if (ars.review_approved === null) {
        return isPast ? 'Not Reviewed' : 'Awaiting Review'
    }

    if (ars.review_approved === false) {
        return 'Rejected'
    }

    const approvedBy = ars.approved_by ? ars.approved_by : 'Admin'

    return approvedBy + ' Approved'
}

export const formatLienColumn = (ars_enabled, file) =>
    ars_enabled ?
        file && file.length > 0 ? (<a href={file} target="_blank" className="lien-file-yes">Y</a>) : (<span>N</span>) :
        "N/A";

export const generalRedoSearchWithoutPresets = ({callback, search_obj, sort_direction = undefined, currentPage = undefined, sort_column = undefined, page_count = undefined, append_options = {}}) => {
    const {
        term,
    } = search_obj;

    const options = {
        page_count, // results per page
        page_num: currentPage, // which page in pagination
        sort_direction,
        sort_column,
        ...append_options,
    };

    if (search_obj.page_num && search_obj.page_num == 1) {
        delete search_obj['page_num'];
    }

    setQueryString({search_obj});

    return callback(term, options);
};

export const cityStateToUrl = ({state, city}) => `${state}/${city}`.toLowerCase()

export const formatApiObj = (search_obj) => {
    const {
        filter_types = [],
        filter_categories = [],
        filter_unit_contents = '',
        type: search_type = 'zipcode',
        radius: search_radius = '100',
        term: search_term = '',
        term_state: search_state = '',
        page_num = 1,
        sort_column,
        sort_direction,
        page_count = 15
    } = search_obj;

    const params = {
        page_num,
        page_count,
        search_type,
        search_term: search_type == 'state' ? search_state : search_term,
        filter_types: filter_types.length > 0 ? filter_types.join(',') : "1,2,3,4",
        filter_categories: filter_categories.length > 0 ? filter_categories.join(',') : "",
        filter_unit_contents,

        search_state: search_type == 'state' ? search_state : undefined,
        search_radius: search_type == 'zipcode' ? search_radius : undefined,

        sort_column,
        sort_direction
    };

    return params
}

export const calculateBidderPremium = (bidConfirmed, premiumPercentage, minimumBid) => {
    if (premiumPercentage && bidConfirmed) {
        const premiumAmount = (premiumPercentage / 100) * Number(minimumBid)
        return premiumAmount > 10 ? premiumAmount : 10
    }

    return 10
}

export const getRestrictionMessage = (reasons) => {
    if (reasons.length === 1) {
        return get_bid_message(reasons[0].slug, false)
    }
    const bodyMap = reasons.map((reason) => get_bid_message(reason.slug).body)
    const reasonsMapped = reasons.map((reason) => ({
        ...get_bid_message(reason.slug, false),
        body: bodyMap
    }))[0];
    
    return reasonsMapped;
}
