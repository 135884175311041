const HelpBlock = ({ help, frontEnd = true }) => {
    if (!help) {
        return null
    }

    return frontEnd ? (
        <div className="help-block">{help}</div>
    ) : (
        <small className="form-text text-muted">{help}</small>
    )
}

export default HelpBlock
