const AuthorizedPickupPersonHeaders = [
    { body: "FIRST NAME", column_map: "fname", className: "text" },
    { body: "LAST NAME", column_map: "lname", className: "text" },
    { body: "EMAIL", column_map: "email", className: "email" },
    { body: "PHONE", column_map: "phone", className: "number" },
    { body: "INVITE STATUS", column_map: "status", className: "text" },
    { body: "USER STATUS", column_map: "user_status", className: "text" },
    false
]

export default AuthorizedPickupPersonHeaders