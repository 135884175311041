import {FILE_POST, FILE_POST_RESET} from "../actions/FileTypes";

const INITIAL_STATE = {
    file_upload_attrs: {
        inc: 0,
        percentage_progress: 0,
        files_updated: 0,
        current_percentage: 0,
        goal_percentage: 100,
        file_name: '',
        uploading: false,
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FILE_POST_RESET:
            return {
                ...state, file_upload_attrs: {
                    inc: 0,
                    percentage_progress: 0,
                    files_updated: 0,
                    current_percentage: 0,
                    goal_percentage: 100,
                    file_name: '',
                    uploading: false,
                    ...action.payload || {}
                }
            };
        case FILE_POST:
            return {...state, file_upload_attrs: {...action.payload}};
        default:
            return state;
    }
}
