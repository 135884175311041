import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import { APP_CONFIG } from 'Config';
import {getAuthedUser} from "../helpers/user";

export const initGTM = async () => {
    const {
        gtmId = undefined,
        gaId = undefined,
    } = APP_CONFIG.Services;

    // Google Tag Manager
    if (gtmId) {
        TagManager.initialize({ gtmId });
    }

    // Google Analytics
    if (gaId) {
        const {user_id = ''} = await getAuthedUser();
        const gaOptions = {};
        if(user_id){
            gaOptions.userId = user_id;
        }

        ReactGA.initialize(gaId, {
            // debug: true,
            gaOptions
        });
    }
};
