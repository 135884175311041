import * as ReactBootstrap from 'react-bootstrap';
import Loading from 'components/Loading'
import {Formik} from 'formik'

const BootstrapWrap = ({
    handleSubmit,
    children,
}) => {
    return (
        <ReactBootstrap.Form noValidate onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            handleSubmit(e)
        }}>
            {children}
        </ReactBootstrap.Form>
    );
}

const BaseForm = ({
    loading,
    children,
    defaultData = {},
    showOptionalLabel = false,
    ...props
}) => {
    if (loading) {
        return <Loading />;
    }
    
    return (
        <Formik
            enableReinitialize
            showOptionalLabel={showOptionalLabel}
            {...props}
        >
            {({handleSubmit, values, errors, ...rest}) => {
                return (
                    <BootstrapWrap handleSubmit={handleSubmit} values={values} errors={errors} {...rest}>
                        {children}
                    </BootstrapWrap>
                )
            }}
        </Formik>
    );
};

export default BaseForm;
