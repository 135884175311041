import { Modal } from 'react-bootstrap'
import Button from 'components/partials/Fields/Button'
import Input from 'components/partials/Fields/FormikInput'
import Form from 'components/FormikForm'
import { useFormikContext } from 'formik'
import yup from 'helpers/validation'
import '../styles.less'

const SubmitButton = props => {
    const { values, isValid } = useFormikContext()

    return (
        <Button
            label="Send Invite"
            variant="primary"
            disabled={!values.authorized_email || !isValid}
            {...props}
        />
    )
}

const AddPickupPerson = ({ 
    email, 
    setEmail, 
    handleConfirm, 
    show, 
    setShow 
}) => {

    const formSchema = yup.object().shape({
        authorized_email: yup.string().email('Invalid email').required('This field is required.')
    })

    return (
        <Modal
            name="add-pickup-person-modal"
            show={show}
            className="add-pickup-person-modal"
            onHide={() => setShow(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Authorized Pickup Person</Modal.Title>
            </Modal.Header>
            <Form
                initialValues={{authorized_email: ''}}
                onSubmit={handleConfirm}
                validationSchema={formSchema}
            >
                <Modal.Body>
                    <Input
                        formik
                        label="Email"
                        name="authorized_email"
                        onChange={(e) => {
                            setEmail(e.value)
                        }} 
                    />
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButton onClick={handleConfirm} />
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddPickupPerson