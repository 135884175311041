import { Auth } from 'aws-amplify';
import storage from 'helpers/storage';
import { APP_CONFIG } from '../Config';

export const getAuthedUser = async () => {
    try{
        const user = await Auth.currentAuthenticatedUser();

        return {
            ...user,
            user_id: user?.attributes['custom:user_id'],
        }
    }catch(err){
        return false;
    }
}

export const is_authed_async = async () => {
    return !!await getAuthedUser();
};

export const is_authed_cache = () => {
    return (storage.getItem('st.authed') || '0') == '1';
};

export const is_guest_async = async () => {
    const is_authed = await is_authed_async();
    return !is_authed;
};

// export const is_guest_cache = () => {
//     return (storage.getItem('st.authed') || '0') == '0';
// }

export const isDevelopment = () => {
    const {
        APP_ENV = 'prod',
    } = APP_CONFIG;

    return ['dev', 'development', 'local'].includes(APP_ENV);
};

export const get_reg_type = (default_value = '0', return_mapped = false, { just_map = false } = {}) => {
    if (!return_mapped) return storage.getItem('st.reg_type') || default_value;

    const reg_types = {
        '0': default_value,
        '1': 'FM',
        '2': 'AB',
        '3': 'AU',
    };

    if(just_map !== false){
        return reg_types[just_map];
    }

    const reg_type = storage.getItem('st.reg_type') || "0";
    return reg_types[!reg_type || reg_type == "false" || reg_type == "" ? "0" : reg_type];
};

export const CANCEL_REASON_NON_PAYMENT = "1";
export const CANCEL_REASON_UNKNOWN = "2";

export const is_user_suspended = ({return_reason = false} = {}) => {
    const suspended = storage.getItem('st.suspended') || false;

    const is_suspended = suspended == true;

    if (!is_suspended) {
        return false;
    }

    if (is_suspended === true && return_reason === true) {
        const suspended_reason = storage.getItem('st.suspended_reason_id') || "0";

        switch (suspended_reason) {
            case CANCEL_REASON_NON_PAYMENT:
                return CANCEL_REASON_NON_PAYMENT;
            case 'unknown':
            default:
                return CANCEL_REASON_UNKNOWN;
        }
    }

    return is_suspended;
};

export const checkRequireAfp = ({user}) => {
    if (user?.require_afp) {
        localStorage.setItem('require_afp', user?.require_afp)
    }
}
