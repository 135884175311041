import React from 'react';
import Button from './Fields/Button';
import Cancel from './Fields/Cancel';
import { waitForElement } from '../../helpers/page';

const SubmitCancelButtons = (props) => {
    const {
        expiresInFive,
        cancelLabel,
        submitLabel = 'Save Changes',
        submit_disabled_label = submitLabel,
        submitHtml = null,
        submit_disable = false,
        submit_disabled_message = false,
        loading = false,
        saving = false,
        cancelConfirmation = true,
        cancel_type = undefined,
        handleCancelForm = () => {},
        onClick
    } = props;

    const wrapButton = (children) => {
        if (submit_disable && submit_disabled_message) {
            waitForElement('#submit-disabled-btn-popover').then(({ element, found = false }) => {
                found && $(element).popover();
            });

            const submitCanceledLabel = (saving) ?
                <i className="fa fa-spin fa-spinner" /> :
                submit_disabled_label;

            return (
                <span
                    id="submit-disabled-btn-popover"
                    data-placement="top"
                    data-toggle="popover"
                    data-trigger="hover"
                    data-content={submit_disabled_message}
                    className="btn btn-blue btn-regular btn-fw marginT20 disabled">
                    {submitCanceledLabel}
                </span>
            );
        }

        try {
            typeof window != 'undefined' && $('#submit-disabled-btn-popover').popover('hide');
        } catch (e) {

        }

        return children;
    };

    return (
        <div className="text-center">
            <div className="row marginB20">
                {wrapButton(
                    <Button
                        label={submitLabel}
                        className="btn btn-blue btn-regular btn-fw marginT20"
                        type="submit"
                        loading={saving}
                        disabled={loading || saving || submit_disable || expiresInFive}
                        onClick={onClick && onClick}
                    >
                        {submitHtml}
                    </Button>,
                )}
            </div>
            <div className="row">
                <Cancel
                    cancel_type={cancel_type}
                    okAction={handleCancelForm}
                    label={cancelLabel}
                    id="cancel"
                    cancelConfirmation={cancelConfirmation}
                    disabled={loading || saving} />
            </div>
        </div>
    );
};

export default SubmitCancelButtons;
