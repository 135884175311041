import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';

import 'styles/components/Alert.less';

class Cancel extends Component {
    state = {
        show: false,

        messages: {
            file: {
                label: '',
                okButton: 'Yes, remove it',
                cancelButton: 'Cancel',
                confirm_title: 'Remove File',
                confirm_message: 'Are you sure you want to remove this file?',
            },
            image: {
                label: '',
                okButton: 'Yes, remove it',
                cancelButton: 'Cancel',
                confirm_title: 'Remove Image',
                confirm_message: 'Are you sure you want to remove this image?',
            },
            registration: {
                label: 'Cancel registration',
                okButton: 'Yes, cancel',
                cancelButton: 'No, stay on this page',
                confirm_title: 'Are you sure?',
                confirm_message: 'Are you sure you want to leave without saving? All of your progress will be lost.',
            },
            page: {
                label: 'Cancel',
                okButton: 'Yes, cancel',
                cancelButton: 'No, stay on this page',
                confirm_title: 'Are you sure?',
                confirm_message: 'Are you sure you want to leave without saving? All of your progress will be lost.',
            },
        },
    };

    onClick = () => {
        const {disabled = false} = this.props;
        if(disabled){
            return true;
        }

        if (this.props.cancelConfirmation === false) {
            this.props.okAction();
            return true;
        }

        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false }, () => {
            this.props.handleClose && this.props.handleClose();
        });
    };

    handleOkClick = () => {
        this.setState({ show: false }, () => {
            this.props.okAction && this.props.okAction();
        });
    };

    getMessage = () => {
        const { cancel_type } = this.props;
        const {
            okButton,
            cancelButton,
            confirm_title,
            confirm_message,
            label,
        } = this.state.messages[cancel_type] || {};

        return {
            confirm_title: this.props.confirm_title || confirm_title,
            confirm_message: this.props.confirm_message || confirm_message,
            okButton: okButton || 'Yes, cancel',
            cancelButton: cancelButton || 'Cancel',
            _label: label,
        };
    };

    render() {
        const {type, label, className, disabled = false, id = 'cancel'} = this.props;

        const {
            confirm_title, confirm_message, okButton, cancelButton, _label,
        } = this.getMessage();

        const label_to_use = label || _label;

        return (
            <>
                {type == 'a'
                    ? <a id={id} className={`link-item ${disabled? 'disabled': ''}`} href={void(0)}
                         onClick={this.onClick}>{label_to_use}</a>
                    : <button id={id} type="button" className={`${className}
                             ${disabled? 'disabled': ''}`} onClick={this.onClick}>{label_to_use}</button>}
                <Modal bsSize="small" show={this.state.show} onHide={this.handleClose}
                       id="model-confirm">
                    <Modal.Header closeButton>
                        <Modal.Title>{confirm_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {confirm_message}
                    </Modal.Body>
                    <Modal.Footer>
                        <a id="cancel-modal-ok" onClick={this.handleOkClick}
                           className="btn btn-primary dialog-ok">{okButton}</a>
                        <a id="cancel-modal-cancel" href={void(0)}
                           onClick={this.handleClose}
                           className="btn dialog-cancel">{cancelButton}</a>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

Cancel.defaultProps = {
    disabled: false,
    cancelConfirmation: true,
    handleClose: false, // function callback to trigger when the close button was clicked.
    type: 'a',
    cancel_type: 'page',
    className: '',
    id: undefined,

    // if these are set they override the state.messages[cancel_type] default
    label: false,
    confirm_title: false,
    confirm_message: false,

    okAction() {
    },
};

export default Cancel;
