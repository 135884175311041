import { objCleanup } from '../helpers/objects';
import BaseService from './BaseService';
import { environment } from './environment';
import { GET, POST, PUT } from './RequestMethods';

class CaseService extends BaseService {
    index = (params = {}) => {
        return this.submitRequestWithPromise(GET, environment.apiEndPoint,
            'users/account/cases', objCleanup(params, false, { filterOut: ['sortColumn'] }));
    };

    show = ({ case_id }) => {
        return this.submitRequestWithPromise(GET, environment.apiEndPoint,
            `users/account/cases/${case_id}`);
    };

    update = ({ case_id, params }) => {
        return this.submitRequestWithPromise(POST, environment.apiEndPoint,
            `users/account/cases/${case_id}`,
            params,
        );
    };

    cancel = ({ case_id, ...params }) => {
        return this.submitRequestWithPromise(PUT, environment.apiEndPoint,
            `users/account/cases/${case_id}/cancel`,
            params,
        );
    };

    create = ({ params }) => {
        return this.submitRequestWithPromise(POST, environment.apiEndPoint,
            "users/account/cases", params);
    };

}

export default new CaseService();
