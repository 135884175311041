import React, { Component } from 'react'
import NavLink from 'components/Page/NavLink'
import 'styles/layouts/Sidebar.less'
import { is_authed_async } from 'helpers/user'
import Logout from 'components/UserAccount/Logout'
import { connect } from 'react-redux'
import SwitchClassic from 'components/SwitchClassic'
import HelpLink from "../components/HelpLink";
import { APP_CONFIG } from 'Config'
class Sidebar extends Component {
    state = {
        menuOpen: false,
        showOverlay: null,
        is_authed: false
    }

    componentDidMount() {
        is_authed_async()
            .then((is_authed) => this.setState({ is_authed }))
            .catch(() => this.setState({ is_authed: false }))
    }

    toggleNav = () => (!this.state.menuOpen ? this.openNav() : this.closeNav())

    openNav = () => this.setState({ menuOpen: true, showOverlay: true })

    closeNav = () => this.setState({ menuOpen: false, showOverlay: false }, () => {
        setTimeout(() => {
            this.setState({ showOverlay: null })
        }, 500) // .5 seconds. there is a css transition to change opacity to 0
    })

    renderLink = (link, title, { external = false } = {}, targetBlank) => {
        const rel = external ? 'noopener noreferrer' : undefined

        return targetBlank
            ? <a href={link} rel={rel} target="_blank" onClick={this.closeNav}>{title}</a>
            : <NavLink to={link} rel={rel} onClick={this.closeNav}>{title}</NavLink>
    }

    renderPostAuction = () => {
        const { reg_type } = this.props

        if (reg_type !== '1' && reg_type !== '4') { // reg type 1 = Facility manager
            return null
        }

        const { renderLink } = this

        return (
            <li className="dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    Post Auction
                    {' '}
                    <span className="fa fa-chevron-down" />
                </a>
                <ul className="dropdown-menu">
                    <li>
                        {renderLink('/account/auctions/drafts/post', 'Online Auctions')}
                        {reg_type !== '4' && renderLink('/account/live-auctions/post', 'Live Auction')}
                    </li>
                </ul>
            </li>
        )
    }

    getOverlayClassStatus = () => {
        const { showOverlay } = this.state

        if (showOverlay === true) {
            return 'opened'
        } if (showOverlay === false) {
            return 'closed'
        }

        return ''
    }

    render() {
        const {
            renderLink, closeNav, renderPostAuction, getOverlayClassStatus, toggleNav, state, props
        } = this
        const { menuOpen } = state
        const { authed, loginLinkRedirect } = props
        const menuClassStatus = menuOpen == true ? 'opened' : 'closed'
        const maintenanceNoticeEnabled = typeof APP_CONFIG?.REACT_APP_MAINTENANCE_NOTICE === 'string' && APP_CONFIG.REACT_APP_MAINTENANCE_NOTICE !== ''

        const is_guest = !authed

        return (
            <div className="sidebar-container sidebar-hide">
                <div className={`overlay ${getOverlayClassStatus()}`} onClick={closeNav} />

                <span className={`right-navbar-toggler ${menuClassStatus} ${maintenanceNoticeEnabled ? 'right-navbar-lower' : ''}`} aria-label="Toggle navigation" onClick={toggleNav}>
                    <i className="toggle-menu fa fa-bars fa-2x" />
                </span>

                <div className={`navbar-side-left ${menuClassStatus}`} id="main-side-nav">
                    <div className="slide-header">
                        <span className="slide-menu-title">Menu</span>
                        <span className={`right-navbar-toggler ${menuClassStatus} inside`} aria-label="Toggle navigation" onClick={toggleNav}>
                            <i className="toggle-menu fa fa-times fa-2x" />
                        </span>
                        <div className="slide-header-buttons">
                            <div className="col-xxs-6 col-xs-6">
                                {is_guest
                                    ? <NavLink className="btn btn-block btn-regular btn-orange slide-header-button" id="sidebar-sign-up" to="/register" onClick={closeNav} title="Sign Up">Sign Up</NavLink>
                                    : null
                                }
                                {authed ? (
                                    <NavLink className="btn btn-block btn-regular btn-orange slide-header-button" id="sidebar-my-account" to="/account/dashboard" onClick={closeNav} title="My Account">
                                        My Account
                                    </NavLink>
                                ) : null}
                            </div>
                            <div className="col-xxs-6 col-xs-6">
                                {is_guest
                                    ? <NavLink className="btn btn-block btn-regular btn-blue slide-header-button" id="sidebar-log-in" to={loginLinkRedirect} onClick={closeNav} title="Log In">Log In</NavLink>
                                    : null
                                }
                                {authed
                                    ? <Logout onClick={closeNav} className="btn btn-block btn-regular btn-blue slide-header-button" />
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    {this.props.showTopMessageBar && <SwitchClassic sidebar />}
                    <ul className="nav navbar-nav" style={{ fontSize: '16px' }}>
                        <li>
                            {renderLink('/', 'Home')}
                        </li>
                        <li>
                            {renderLink('/auctions?type=zipcode&radius=100&filter_types=1&filter_types=2&filter_types=3', 'Online Auctions')}
                        </li>
                        <li>
                            {renderLink('/live-auctions?type=zipcode&radius=100term=', 'Live Auctions')}
                        </li>
                            {renderPostAuction()}
                        <li className="dropdown">
                            <a
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Pro Community
                                {' '}
                                <span className="fa fa-chevron-down" />
                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    {renderLink('http://blog.storagetreasures.com', 'Auction Pro Blog', {}, true)}
                                </li>
                                <li>
                                    {renderLink('/pro-membership-upgrade', 'Upgrade to Pro')}
                                </li>
                                <li>
                                    {renderLink('/online-storage-auction-app', 'StorageTreasures App')}
                                </li>
                            </ul>
                        </li>
                        <li className="sidebar-item-about dropdown">
                            <a
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                About Us
                                {' '}
                                <span className="fa fa-chevron-down" />
                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    {renderLink('/about', 'StorageTreasures')}
                                </li>
                                <li>
                                    {renderLink('https://www.opentechalliance.com/about-us/', 'OpenTech Alliance', {}, true)}
                                </li>
                                <li>
                                    {renderLink('https://www.charitystorage.org', 'Charity Storage', {}, true)}
                                </li>
                            </ul>
                        </li>
                        <li>
                            <HelpLink>Help Center</HelpLink>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ user, page }) => {
    const {
        authed = false,
        reg_type = false
    } = user

    const {
        loginLinkRedirect
    } = page.data || {}

    return {
        authed,
        reg_type,
        loginLinkRedirect
    }
}

export default connect(mapStateToProps)(Sidebar)
