import {GET_LIVE_AUCTION_FAIL, GET_LIVE_AUCTION_SUCCESS, GET_LIVE_AUCTIONS} from "actions/LiveAuctionTypes";

import {get, range} from 'lodash';
import {
    CANCEL_LIVE_AUCTION_FAIL,
    CANCEL_LIVE_AUCTION_SUCCESS,
    FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS,
    FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_FAIL,
    FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_SUCCESS,
    FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE,
    FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_FAIL,
    FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_SUCCESS,
    FACILITY_MANAGER_LIVE_AUCTION_FORM_UPDATE
} from "actions/FacilityManagerTypes";
import {user_unformatted_date, user_unformatted_time} from "helpers/date";
import {general_error_toastr, general_success_toastr} from "../components/partials/Notification";

const INITIAL_STATE = {
    search_loading: false,
    loading: true,
    error: false,
    error_message: '',

    auctions: [],

    total_records: 0,

    searchType: 'zipcode',
    search: '',
    radius: '100',

    number_of_units: [{label: "Call for Details", value: "0"}, ...range(1, 76).map(num => ({label: num + "", value: num + ""}))],

    // If the order of this changes please update the Live auction TopSearch Component include. It needs to exclude 'keyword'
    searchTypes: [
        {id: 'zipcode', name: 'Zip Code/Postal Code'},
        {id: 'state', name: "State/Province"},
        {id: 'city', name: "City"},
        {id: 'facility', name: 'Facility Name'}
    ],

    searchRadius: [
        {id: 'entire', name: 'Exact'},
        {id: 5, name: '5 mi'},
        {id: 10, name: '10 mi'},
        {id: 25, name: '25 mi'},
        {id: 50, name: '50 mi'},
        {id: 100, name: '100 mi'},
        {id: 150, name: '150 mi'},
        {id: 200, name: '200 mi'},
        {id: 500, name: '500 mi'},
    ],

    form_data: {},
    form_data_loading: null,
    form_data_error: false,
};

export default (state = INITIAL_STATE, action) => {

    const {payload = {}, error = false} = action;
    const {live_auction = {}} = payload;

    switch (action.type) {

        case GET_LIVE_AUCTIONS:
            return {...state, search_loading: true, loading: true, error, total_records: 0, auctions: []};
        case GET_LIVE_AUCTION_SUCCESS:
            return {...state, search_loading: false, loading: false, total_records: get(payload, 'total_records', 0), auctions: get(payload, 'live_auctions', [])};
        case GET_LIVE_AUCTION_FAIL:
            return {...state, search_loading: false, loading: false, error: true, totalResultCount: 0,  error_message: GET_LIVE_AUCTION_FAIL};

        case FACILITY_MANAGER_LIVE_AUCTION_FORM_UPDATE:
            const {clear_all} = action;
            if (clear_all === true) {
                return {
                    ...state, form_data: {
                        expire_date: '',
                        expire_time: '',
                    }
                };
            }
            return {...state, form_data: {...state.form_data, [action.name]: action.value}};

        case FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS:
            return {...state, form_data_loading: true, form_data_error: false};
        case FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_SUCCESS:
            const {expire_date, facility_id, unit_count} = live_auction;
            return {...state, form_data_loading: false, form_data: {
                    facility_id,
                    unit_count,
                    expire_date: user_unformatted_date(expire_date),
                    expire_time: user_unformatted_time(expire_date),
                }};
        case FACILITY_MANAGER_GET_LIVE_AUCTION_DETAILS_FAIL:
            return {...state, form_data_loading: false, form_data_error: error};

        case CANCEL_LIVE_AUCTION_SUCCESS:
            general_success_toastr({title: 'Success!', message: 'Live auction has been removed.'});
            return {...state};
        case CANCEL_LIVE_AUCTION_FAIL:
            general_error_toastr({title: 'Uh Oh.', message: 'Live auction could not be removed at this time. Please try again.'});
            return {...state};
        case FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE:
            return {...state, form_data_loading: true, form_data_error: error};
        case FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_SUCCESS:
            return {...state, form_data_loading: false, form_data_error: error};
        case FACILITY_MANAGER_GET_LIVE_AUCTION_SAVE_FAIL:
            return {...state, form_data_loading: false, form_data_error: error};

        default:
            return state;
    }
}
