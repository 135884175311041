/**
 * Constants to be used throughout the application
 * @type {string}
 */

export const FM = '1' // facility manager
export const AB = '2' // auction buyer
export const PS = '4' // private seller

export const ROLES = [FM, AB]

export const GUEST = '1000' // is guest
export const AUTHED = '1001' // is authed

export const FAILS_ACCOUNT = 'fails_account'
export const FAILS_AUTH = 'fails_auth'
export const FAILS_GUEST = 'fails_guest'
export const FAILS_UNAUTH = 'fails_unauth'
