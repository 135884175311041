import axios from 'axios';
import { GET } from './RequestMethods';
import { redirectLogin, statusSet } from 'helpers/page';
import { checkRequireAfp, is_authed_async } from 'helpers/user';
import { get, includes, set } from 'lodash';

import store from 'utils/reduxStore';
import { doLogout } from '../actions';
import { general_error_toastr } from '../components/partials/Notification';

import { Auth } from 'aws-amplify';
import { randomString } from 'helpers/string';
import { logout } from '../actions/AuthActions';

import { environment } from "../services/environment";


let cancel_obj = {};

/**
 * Allow component to cancel an api request
 * @param key
 * @returns {boolean}
 */
export const trigger_cancel = (key) => {
    const cancel_func = get(cancel_obj, key);

    if (!cancel_func) {
        return Promise.resolve('cancel_func.missing');
    }

    cancel_func('canceled')
    cancel_obj = set(cancel_obj, key, undefined);
};

export default class BaseService {
    allow_404_redirect = false;
    cancel_id = undefined;

    async submitRequestWithPromise(method, baseURL, url, params = {}, options = {}, allow_404_redirect, headers = {}) {
        this.allow_404_redirect = allow_404_redirect;

        let cancelToken = undefined;

        const {
            authUrl = false,
            cancel_id = this.cancel_id,
        } = options || {};

        if (authUrl) {
            url = await this.publicAuthedUrl(url);
        }

        if (cancel_id) {
            const tmp_cancel_token = axios.CancelToken.source();

            cancelToken = await tmp_cancel_token.token;
            set(cancel_obj, cancel_id, tmp_cancel_token.cancel);
        }

        return this.accessToken().then(async auth_token => {

            // add cache busting for internet explorer
            if (method == GET) set(params, 'randStr', randomString());

            const config = {
                url,
                baseURL,
                method,
                headers: {
                    ...{
                        'x-api-key': environment.apiKey,
                        "content-type": 'application/json',
                    },
                    // Merge headers passed in with the presets
                    ...headers
                },

                cancelToken,

                [method == GET ? 'params' : 'data']: params,
            };

            // if auth then set the token
            if (auth_token) {
                config.headers['Authorization'] = `Bearer ${auth_token}`;
            }

            axios.interceptors.request.use((config) => {
                statusSet('loading', true);

                return config;
            }, (error) => {
                statusSet('loading', false);

                return Promise.reject(error);
            });

            axios.interceptors.response.use(
                async response => {
                    statusSet('loading', false);

                    return response
                }, async error => {
                    const {response} = error;
                    const {status = '0'} = response || {};
                    const dispatch = store().dispatch;

                    if (axios.isCancel(error)) {
                        return Promise.reject({message: 'canceled', response: {status: 204}});
                    }

                    // Do something with response error
                    switch (status) {
                        case 401: // access denied, usually a bad oath taken
                            // Propagate the logout throughout the application
                            await dispatch(logout());
                            await dispatch(doLogout());

                            if (includes(window.location.pathname, '/account')) {
                                return redirectLogin();
                            }

                            break;
                        // case 403: // forbidden
                        //     TODO :: account for this
                        // case 503: // Unreachable.
                        //     TODO :: account for this
                        // case 504: // Gateway timeout
                        //     TODO :: account for this
                        case 500:
                            general_error_toastr();
                            break;
                    }
                    return Promise.reject(error);
                });

            return axios
                .request(config)
                .then(response => {
                    checkRequireAfp(response.data)
                    return response.data
                });
        });
    }

    async accessToken() {

        try {
            const res = await Auth.currentSession();
            const idToken = res.idToken;

            return idToken.getJwtToken();
        } catch (e) {
            return false;
        }
    }

    missingData = (message) => new Promise((resolve, reject) => reject(message));

    buildFileParams = (file, field_name = 'file') => {
        const headers = {
            'content-type': 'multipart/form-data'
        };
        const params = new FormData();
        params.append(field_name, file, file.name);
        params.append('type', file.type);
        params.append('name', file.name);
        params.append('size', file.size);

        return {params, headers};
    };

    publicAuthedUrl = async (public_url, authed_url = true) => {
        const authed = await is_authed_async();

        if (authed && authed_url == true) {
            return 'authed/' + public_url;
        } else if (authed) {
            return authed_url;
        }

        return public_url;
    };

    customPublicAuthedUrl = async ({ authed_url, public_url }) => {
        const authed = await is_authed_async();

        return authed ? authed_url : public_url;
    };
}
