import React from 'react'
import { APP_CONFIG } from 'Config'
import Error503 from 'components/Errors/Error503'

const { REACT_APP_MAINTENANCE_MODE } = APP_CONFIG

const MaintenanceWrapper = ({ children }: { children: React.FC }) => {
    return REACT_APP_MAINTENANCE_MODE ? <Error503 /> : children
}

export default MaintenanceWrapper