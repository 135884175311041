import React from 'react'
import { connect } from 'react-redux'
import { FACILITY_MANAGER, PRIVATE_SELLER } from '../Enums/user'
import { useGlobalContext } from '../context/GlobalContext'

export const userTypeMap = { 1: 'Facility Manager', 2: 'Auction Buyer' }

const HelpLink = ({
    children,
    prepend,
    append,
    url = '',
    supportEmail = false,
    supportEmailPrepend = '',
    text = 'Submit a request',
    showSupportEmail,
    isLink = true,
    user: propsUser,
    ...props
}) => {
    const { user: _user } = useGlobalContext()
    const user = propsUser || _user

    let _showSupportEmail = user?.reg_type == FACILITY_MANAGER || user?.reg_type == PRIVATE_SELLER

    if (typeof showSupportEmail === 'boolean') _showSupportEmail = supportEmail

    const getUserMembershipInfo = (type) => {
        switch (type) {
            case 'ProBidder':
                return {
                    membershipType: 'probidder',
                    membershipPb: 'pb_regular'
                }
            case 'ProBidder Max':
                return {
                    membershipType: 'probidder_max',
                    membershipPb: 'pbm_high'
                }
            case 'ProBusiness':
                return {
                    membershipType: 'probusiness',
                    membershipPb: 'pb_important'
                }
            case 'Basic':
            case 'Basic Bidder':
            default:
                return {
                    membershipType: 'basic_bidder',
                    membershipPb: 'bb_standard'
                }
        }
    }

    const getUserTypeName = (typeName) => {
        if (!typeName) return ''
        return typeName.toLowerCase().replace(/\s+/g, '_')
    }

    const params = user?.user_id ? `?tf_anonymous_requester_email=${user?.email}&tf_25303334875163=${getUserMembershipInfo(user?.membership?.type)?.membershipType}&tf_25818560241947=${getUserMembershipInfo(user?.membership?.type)?.membershipPb}&tf_80640587=${getUserTypeName(user?.type_name)}` : ''
    const contact = _showSupportEmail ? 'support@storagetreasures.com' : text
    const contactHref = _showSupportEmail ? 'mailto:support@storagetreasures.com' : `https://help.storagetreasures.com/hc/en-us${url + params}`


    if (typeof children === 'function') return children({ contact, contactHref, showSupportEmail: _showSupportEmail })

    const Component = isLink ? 'a' : 'span'

    if (_showSupportEmail) {
        return (
            <>
                <Component
                    href="mailto:support@storagetreasures.com"
                    target="_blank"
                    rel="nofollow noopener"
                    {...props}
                >
                    support@storagetreasures.com
                </Component>
            </>

        )
    }

    return (
        <>
            {prepend}
            <Component
                href={`https://help.storagetreasures.com/hc/en-us${url + params}`}
                target="_blank"
                rel="nofollow noopener"
                {...props}
            >
                {children}
            </Component>
            {append}
        </>
    )
}

export default HelpLink
