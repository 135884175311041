import MainLayout from 'layouts/MainLayout'
import OtherLayout from 'layouts/OtherLayout'
import React from 'react'
import OtherAuctions from 'components/Auctions/OtherAuctions'
import BlankLayout from 'layouts/BlankLayout'
import FacilityManagerNavigation from 'layouts/FacilityManagerNavigation'
import BuyerNavigation from 'layouts/BuyerNavigation'
import DoRedirect from 'components/Page/DoRedirect'
import { general_error_toastr } from 'components/partials/Notification'

export default (url, { reg_type = 0, userId }) => {

    let layout = OtherLayout
    let attributes = {
        show_notification_area: true,
        showTopMessageBar: false,
    }

    switch (url.toLowerCase()
        .replace(/^\//, '')) {
        case 'status/health':
            layout = BlankLayout
            break
        case 'account/reset-password':
            layout = MainLayout

            attributes.with_container = false
            attributes.show_notification_area = false
            attributes.add_margin_top = false
            break

        case 'about':
            attributes.add_bottom_top = false
        case '':
        case '/':
        case 'account/reset-requested':
        case 'pro-membership-upgrade':
            layout = OtherLayout

            attributes.with_container = false
            attributes.show_notification_area = false
            attributes.add_margin_top = false
            attributes.add_bottom_top = false
            break

        case 'live-auctions':
            layout = OtherLayout

            attributes.with_container = false
            break

        case 'account/change-password':
            layout = MainLayout
            break

        case 'auctions/[state]/[city]/[auction_id]':
            layout = MainLayout
            attributes = {subComponent: OtherAuctions}
            break

        case 'generate/invoices/invoice/[invoice_id]':
        case 'account/login':
        case 'account/mfa':
            layout = BlankLayout
            break

        default:
            if (url.startsWith('/account')) {
                layout = OtherLayout

                const account_type = reg_type
                attributes.with_container = true

                switch (account_type + "") {
                    case "1": // Facility Manager
                    case "4": // Private Seller
                        attributes.subNav = <FacilityManagerNavigation reg_type={account_type} />
                        break
                    case "2": // Buyer
                        attributes.subNav = <BuyerNavigation userId={userId} />
                        break
                    case 'reg-type-set': // reg type is not set the user must not be logged in..
                    default:
                        general_error_toastr({message: 'Sorry, you cannot access that page.'});
                        <DoRedirect to="/account/dashboard"/>
                        break
                }
            }
    }

    return {component: layout, attributes}
}
