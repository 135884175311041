export const GET_INVOICES = "get_invoices";
export const GET_INVOICES_SUCCESS = "get_invoices_success";
export const GET_INVOICES_FAIL = "get_invoices_fail";

export const INVOICE_INPUT_FIELD_CHANGE = "invoice_input_field_change";

export const DO_INVOICE_EXPORT = "do_invoice_export";
export const DO_INVOICE_EXPORT_SUCCESS = "do_invoice_export_success";
export const DO_INVOICE_EXPORT_FAIL = "do_invoice_export_fail";

export const GET_INVOICE = "get_invoice";
export const GET_INVOICE_SUCCESS = "get_invoice_success";
export const GET_INVOICE_FAIL = "get_invoice_fail";

export const UPDATE_INVOICE = "update_invoice";
