import {
    REGISTRATION_INPUT_CHANGE,
    REGISTRATION_INPUT_CLEAR,
    REGISTRATION_STEP1_DONE,
    REGISTRATION_STEP2_DONE,
    SET_VERIFICATION_QUESTIONS_STATUS,
    CORRECT_ANSWERS_IDENTYFLOW
} from "actions/RegistrationTypes";

import {get, set} from 'lodash';
// import {randomString} from "../helpers/string";

// const attempt = randomString().replace(/@/, '');

const INITIAL_STATE = {
    loading: false,
    error: null,
    user_id: null,

    data: {


        // Step 1 of the registration process
        // api:/register/create-user
        user: {
            full: 'fname lname',

            // reg_type_id: '2',
            // fname: 'a12',
            // lname: 'a',
            // email: `zach2825+tester${attempt}@gmail.com`,
            // email_confirm: `zach2825+tester${attempt}@gmail.com`,
            // phone: '(231) 313-1231',
            // country_id: '237',
            // address: 'asd',
            // city: 'asd',
            // state_id: '3680',
            // timezone_id: "1",
            // zipcode: '23131',
            // password: 'Devqa!!1',
            // password_confirm: 'Devqa!!1',
            // question: '2',
            // answer: 'asd',
            // heard: '16',
        },

        // step2 of the registration
        // api:/register/create-facility
        facility: {
            // facility_name: 'a', // TODO change to facility_name
            // custom_id: 'a',
            // phone: '(242) 342-3423',
            // country_id: '237', // TODO change this to country_id
            // address: 'Animi nesciunt aut sunt ipsum numquam in ea Nam enim quis placeat ut dolores',
            // city: 'Necessitatibus dolorum sunt et magna cupidatat ut',
            // state_id: '3689', // TODO: change this to state_id
            // zipcode: '30157',
            // uri_prefix: 'https',
            // website: 'google.com',
            // pms_id: '3'
        }

    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTRATION_STEP1_DONE:

            return {...state, loading: true, error: null};

        case REGISTRATION_STEP1_DONE + "_SUCCESS":

            const {user_id: _new_user_id} = action.payload;
            return {...state, user_id: _new_user_id, loading: false, error: null};

        case REGISTRATION_STEP1_DONE + "_FAIL":
            switch (get(action, 'payload.err.status')) {
                case "409": // conflict
                    alert( "we're having a conflict");
                    state.duplicate_email = true;
                    break;
                default:
                    // attempting to catch api response errors in a meaningful way
            }

            return {...state, loading: false, error: action.payload};

        case REGISTRATION_STEP2_DONE:

            return {...state, loading: true, error: null};

        case REGISTRATION_STEP2_DONE + "_SUCCESS":
            return {...state, loading: false};
        case REGISTRATION_STEP2_DONE + "_FAIL":
            return {...state, loading: false, error: action.payload};

        case REGISTRATION_INPUT_CHANGE:
            // initialize and/or set the form value
            let input_type = get(action, 'input_type', 'user');

            set(state.data, input_type  + "." + action.name, action.value);

            return {...state};

        case REGISTRATION_INPUT_CLEAR:
            state.data = {
                user: {},
                facility: {},
                user_id: null
            };

            return {...state};

        case SET_VERIFICATION_QUESTIONS_STATUS:
            state.data = {...state.data, verification_questions_status: action.payload};

            return state;

        case CORRECT_ANSWERS_IDENTYFLOW:
            state = {...state, correctAnswersIdentyFlow: action.payload};

            return state;
        default:
            return state;
    }
}
