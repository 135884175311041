import {LOAD_PAYMENT_TOKEN} from "actions/PaymentGatewayTypes";

const INITIAL_STATE = {
    loading: false,
    error: null,

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_PAYMENT_TOKEN:
            return {...state, token: action.token};
        default:
            return state;
    }
}
