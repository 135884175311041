import React, {useEffect} from 'react'
import Afp from '../../pages/account/login/Afp'
import { useGlobalContext } from '../../context/GlobalContext'
import { useRouter } from 'next/router'
const LOGIN_LOGO = '/static/images/login-logo.jpg'

const AfpCheckWrapper = ({children}) => {
    const router = useRouter()
    const { user, loading } = useGlobalContext()

    const handleDone = () => {
        localStorage.setItem('require_afp', 'false')

        router.reload()
    }

    useEffect(() => {
        if (typeof localStorage !== 'undefined' && user?.user_id) {
            localStorage.setItem('require_afp', user?.require_afp ? 'true' : 'false')
        }
    }, [user?.require_afp])

    if (typeof localStorage !== 'undefined' &&
        localStorage.getItem('require_afp') === 'true' &&
        user?.user_id &&
        user?.membership?.type !== 'Basic Bidder' &&
        !loading
    ) {
        return (
            <div className="container login-page">
                <div className="row">
                    <div className="col-md-offset-2 col-md-8 marginT35">
                        <div className="text-center st-logo marginT35 marginB30">
                            <img src={LOGIN_LOGO} alt="Login logo" />
                        </div>
                        <Afp user={user} done={handleDone} />
                    </div>
                </div>
            </div>
        )
    }

    return children
}

export default AfpCheckWrapper
