import { Modal } from 'react-bootstrap'
import Button from 'components/partials/Fields/Button'
import { APP_CONFIG } from 'Config'
import '../styles.less'

const ManageInviteModal = ({ 
    show = false,
    invite = {},
    handleConfirm = () => {},
    handleReject = () => {}, 
    setShow = () => {}
}) => {
    const { REACT_APP_UPDATE_MEMBERSHIP } = APP_CONFIG

    const handleHide = () => {
        setShow(false)
    }

    return (
        <Modal
            name="manage-pickup-person-modal"
            show={show}
            className="add-pickup-person-modal"
            onHide={handleHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>Invitation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    You have been invited to be an Authorized Pickup Person by {invite?.fname} {invite?.lname?.trim()[0]}.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    label="Decline"
                    className="btn btn-blue btn-regular btn-fw marginT20"
                    onClick={handleReject}
                />
                <Button
                    label="Accept"
                    className="btn btn-blue btn-regular btn-fw marginT20"
                    onClick={handleConfirm}
                    disabled={!REACT_APP_UPDATE_MEMBERSHIP}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default ManageInviteModal
