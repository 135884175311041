import React, { useState } from 'react';
import { BasicAlert } from './partials/Alert';
import { connect } from 'react-redux';
import storage from '../helpers/storage';
import Link from './Link';

const SMSOptInAlert = ({ authed, with_container = true, reg_type }) => {
    const [showAlert, setShowAlert] = useState(false);

    // useEffect(() => {
    //     const item = storage.getItem('st.sms_feature') || false;
    //     setShowAlert(!item && reg_type == AUCTION_BUYER);
    // }, []);

    return null;

    const handleDismiss = async () => {
        setShowAlert(false);
        storage.setItem('st.sms_feature', false);
    };

    if (!authed) {
        return null;
    }

    const message = (<>
            <p>On 5/5/2021 we launched a feature that gives self storage facilities the ability to request backup
                bidders and offer unsold units to their previous bidders via their account. This means you will get
                these offers in real-time.<br /><br />
            </p>
            <p style={{
                paddingLeft: 30,
                paddingRight: 30,
            }}>
                Two new offer types include:<br /><br /><b>Unit Liquidation Program (ULP)</b><br />
                The Unit Liquidation Program allows you to receive no cost offers on units that received zero
                bids or were not cleaned out by the winning bidder. These units are FREE!<br /><br />
                <b>Backup Bidder (BUB)</b><br />
                Backup Bidder offers occur when the winning bidder does not clean out a unit. As a fellow
                bidder, you are automatically considered for this type of offer!
            </p>
            <br />
            <hr />
            <p className="mb-0">You have been opted in to receive both Backup Bidder and Unit Liquidation Program
                offers via email and text message. To opt out or change your notification delivery preferences visit
                your <Link className="account-settings-link" to="/account/offers">My Offers</Link> account section.
            </p>
        </>
    );

    return (
        <div className={`${with_container ? '' : 'container'}`}>
            <BasicAlert
                show={showAlert}
                type="success"
                title="New Feature Announcement!"
                onHide={handleDismiss}
                message={message}
            />
        </div>
    );
};

const mapStateToProps = ({ user: { authed, reg_type } }) => ({ authed, reg_type });

export default connect(mapStateToProps)(SMSOptInAlert);
