import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../partials/Fields/Button'
import moment from 'moment'
import Cookies from 'js-cookie'
import { doLogout } from '../../actions'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'

const LogoutCheckWrapper = ({children, doLogout, user}) => {
    const router = useRouter()
    const [firedModal, setFiredModal] = useState(false)
    const [sessionExpired, setSessionExpired] = useState(false)

    const checkLoginExpired = () => {
        if (user.authed) {
            const now = moment().utc().unix()
            const expireDate = +Cookies.get('otp_expires')
            let warningDate = expireDate - 120

            if (now > expireDate) {
                router.push('/account/login')
                window.$('.modal').modal('hide')
                window.$('.modal-backdrop').modal('hide')
                Cookies.remove('otp_expires')
                return doLogout()
            }

            if (!firedModal) {
                if (now < expireDate && now > warningDate) {
                    if (!sessionExpired) {
                        setSessionExpired(true)
                        setFiredModal(true)
                    }
                }
            }
        }
    }

    useEffect(() => checkLoginExpired)

    return (
        <>
            <Modal
                name="logoutmodal"
                show={sessionExpired}
                onHide={() => setSessionExpired(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Logout Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Due to MFA process, you will be logged out automatically within 2 minutes. Please finalize any bids if needed.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setSessionExpired(false)}
                        label="OK"
                        variant="primary"
                    />
                </Modal.Footer>
            </Modal>
            {children}
        </>
    );
};

export default connect(({user}) => ({user}), {doLogout})(LogoutCheckWrapper);
