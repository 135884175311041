import BtnDropdown from 'components/partials/BtnDropdown'

const actions = ( inviteId, { setShowRemovePickupPerson, setPickupPersonId }) => (
    <BtnDropdown>
        <li 
            className='actions-menu-item'
            onClick={() => {
				setPickupPersonId(inviteId)
				setShowRemovePickupPerson(true)
			}}
        >
            Remove
        </li>
    </BtnDropdown>
)

export const formatResults = (results = [], callbacks) => {

	return results.map(app => {
		const {
			fname,
			lname,
			email,
			phone,
			status,
			is_eligible: isEligible,
			invite_id: inviteId
		} = app

		const columns = [
            { body: status !== 'Accepted' ? '' : fname, className: 'text' },
			{ body: status !== 'Accepted' ? '' : lname, className: 'text' },
            { body: email, className: 'email' },
			{ body: status !== 'Accepted' ? '' : phone, className: 'number' },
            { body: status, className: 'text' },
            { body: isEligible ? 'Eligible' : 'Not Eligible', className: 'text' },
			actions(inviteId, callbacks)
		]

		return {
			columns
		}
	})
}