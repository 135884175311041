import {PUT} from "./RequestMethods";
import BaseServiceS3 from "./BaseServiceS3";

    class IdImageService extends BaseServiceS3 {
    postImage = ({url, file}) => {
        return this.submitRequestWithPromise(PUT, url, file);
    };
}

export default new IdImageService();
