import * as yup from 'yup'
import { Auth } from 'aws-amplify'
import { environment } from 'services/environment'
import axios from 'axios'

const getBearerToken = async () => {
    const { idToken: { jwtToken } = {} } = (await Auth.currentSession()) || {}

    return jwtToken
}

yup.addMethod(yup.string, 'alpha', function (msg) {
    return this.matches(/^[A-Za-z\s]+$/, msg)
})

yup.addMethod(yup.number, 'percent', function (msg) {
    return this
        .typeError(msg)
        .integer('Only whole numbers allowed')
        .min(0, 'This value should be between 0 and 100.')
        .max(100, 'This value should be between 0 and 100.')
})

yup.addMethod(yup.number, 'wholeNumber', function (msg) {
    return this
        .typeError(msg)
        .integer('Only whole numbers allowed')
        .min(0, 'This value should be a positive number')
})

yup.addMethod(yup.string, 'dupeEmail', function (
    msg = 'Email has already been registered.',
    initEmail
) {
    return this
        .test(
            'email-check',
            msg,
            async email => {

                if (email === initEmail) {
                    return true
                }

                if (email?.includes('@')) {
                    const jwtToken = await getBearerToken()

                    const { data: { message }} = await axios({
                        url: environment.apiEndPoint + `users/check/email-exists?email=${email}`,
                        headers: {
                            "x-api-key": environment.apiKey,
                            "content-type": "application/json",
                            'Authorization': `Bearer ${jwtToken}`
                        },
                        params: {
                            email: email,
                        }
                    })

                    return message !== 'true'
                }

                return true
        }
    )
})

let _originalValueValid = ''
let changedValid = false
yup.addMethod(yup.string, 'validPhone', function (msg = 'Please enter a valid phone.') {
    return this
        .test(
            'validPhone',
            msg,
            (phone, { originalValue }) => {
                if (!changedValid) {
                    _originalValueValid = originalValue
                    changedValid = true
                }
                if (phone !== undefined) {
                    let _value = phone.replace(/\D/g, '')
                    return _value.length > 10
                }
            return false
        })
})

yup.addMethod(yup.string, 'strongPassword', function (msg) {
    return this
        .typeError(msg)
        .default(false)
        .nullable(true)
        .trim()
        .ensure()
        .when('password_confirm', (password_confirm, schema) => {
            if (password_confirm || password_confirm === undefined) {
                return schema
                    .matches(
                        /^.*(?=.{6,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                        'Please make sure to fill out all of the Password Requirements marked in red.'
                    )
                    .required(
                        'Please make sure to fill out all of the Password Requirements marked in red.'
                    )
            } else {
                return schema.nullable().notRequired();
            }
        })
})

yup.addMethod(yup.string, 'prevPassword', function (
    msg = 'Please make sure to fill out all of the Password Requirements marked in red.',
    userId,
    fireCheck = false
) {
    return this
        .test(
            'password-check',
            msg,
            async _password => {

                if (fireCheck && _password.length > 0) {
                    const jwtToken = await getBearerToken()

                    const { data: { password }} = await axios({
                        method: 'POST',
                        url: environment.apiEndPoint + `users/account/check/password`,
                        headers: {
                            "x-api-key": environment.apiKey,
                            "content-type": "application/json",
                            'Authorization': `Bearer ${jwtToken}`
                        },
                        data: { check: _password }
                    })
                    return password.last_five !== true || password.six_months !== true
                }

                return true
            }
        )
})

yup.addMethod(yup.string, 'passwordConfirmation', function (msg) {
    return this
        .typeError(msg)
        .default(false)
        .nullable(true)
        .trim()
        .ensure()
        .test('passwordConfirmation', 'Passwords do not match', (value) => {
            try {
                if (value === password.value) {
                    return true
                }
                return false
            } catch (e) {
                return false
            }
        })
})

export default yup
