import ReactGA from 'react-ga4';

export const trackNavigation = ({ user_id = '', debug = false }) => {
    debug && console.log('trackNavigation.fired', { user_id });

    ReactGA.event({
        category: 'User',
        action: 'Navigation',
    });

    if (window.dataLayer) {
        window.dataLayer.push({
            user: {
                userId: user_id,
            },
        });
    }
};
