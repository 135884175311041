import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';

class AuctionRibbon extends Component {
    getIsCurrentBidder = ({ user = { is_high_bidder: false } }) => (
        user.is_high_bidder === true
            ? { text: 'High Bidder', text_modifier: 'high-bidder' }
            : { text: 'Outbid', text_modifier: 'outbid' }
    );

    renderTheStuff = () => {
        const { text, text_modifier } = this.getIsCurrentBidder(this.props.auction);

        const { position } = this.props;

        return (
          <div className={`ribbon ${text_modifier} ${position}`}>
            <div className="ribbon-triangle" />
            <div className="ribbon-text">
              {text.toUpperCase()}
            </div>
          </div>
        );
    };

    render() {
        const { authed, auction } = this.props;

        return (!authed || get(auction, 'user.is_bidder', false) === false)
            ? ''
            : this.renderTheStuff();
    }
}

AuctionRibbon.defaultProps = {
    position: 'top',
    auction: {},
};

AuctionRibbon.propTypes = {
    position: PropTypes.oneOf(['top', 'bottom']),
    auction: PropTypes.object,
};

const mapStateToProps = ({ user }) => {
    const { authed } = user;
    return {
        authed,
    };
};

export default connect(mapStateToProps)(AuctionRibbon);
