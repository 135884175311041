import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPage } from 'actions';
import 'styles/layouts/BuyerNavigation.less';
import UserPageTitle from 'components/partials/UserPageTitle';
import { debounce, get, includes } from 'lodash';
import NavLink from 'components/Page/NavLink';

class UserSubNavigation extends Component {
    state = {
        open: false,
    };

    componentDidMount() {
        this.triggerClose = debounce(this.triggerClose, 10);
    }

    toggleNavState = () => this.setState({ open: !this.state.open });

    triggerClose = () => this.state.open === true && typeof window !== 'undefined' && window.$('.navbar-toggler')
        .click();

    render() {
        const { collapse_at_sm, items = [] } = this.props;

        return (
            <span>
            <div className="navbar navbar-dark ">
              <div className="navbar-container">
                <nav
                    className={collapse_at_sm ? 'navbar navbar-dark hidden-xxs hidden-xs hidden-sm navbar-lg' : 'navbar navbar-dark hidden-xxs hidden-xs navbar-lg'}>
                  <div className="navbar-lg ">
                    <div className="navbar navbar-dark" id="navbar">
                      <ul className="nav dashboard-nav navbar-dark" style={{
                          fontSize: '16px',
                          display: 'flex',
                          width: '100%'
                      }}>
                        {items
                            .filter((item) => !!item)
                            .map(({ className, name, sub, link, ...item } = {}) => {
                                let id = undefined;

                                if (item.id) {
                                    id = `account-nav-${item.id}`;
                                }

                                return (
                                    <li
                                        className={className}
                                        key={name}
                                        style={this.props.items[0].name == name ? {
                                            flexGrow: 2,
                                            textAlign: 'center'
                                        } : {
                                            flexGrow: 1,
                                            textAlign: 'center',
                                            borderLeft: '2px solid #000'
                                        }}
                                    >
                                        {sub.length > 0
                                            ? (
                                                <>
                                                    <a
                                                        className={`dropdown-toggle dashboard-link ${includes(item.enum, this.props.menuEnum) ? 'active' : ''}`}
                                                        data-toggle="dropdown"
                                                        role="button"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        id={id}
                                                    >
                                                        {name}
                                                        {' '}
                                                        <span
                                                            className="fa fa-chevron-down dashboard-icon"/>
                                                    </a>
                                                    <ul className="dashboard-dropdown-menu dropdown-menu">
                                                        {sub.map(
                                                            ({ name: subName, id: subId, link: subLink }, index) => (
                                                                <li
                                                                    className="dashboard-link"
                                                                    key={`${subName}-${index}`}
                                                                >
                                                                    <NavLink
                                                                        to={subLink}
                                                                        id={`${id || 'child'}-${subId}`}
                                                                        className="dashboard-link"
                                                                    >{subName}</NavLink>
                                                                </li>
                                                        ))}

                                                    </ul>
                                                </>
                                            )
                                            : <NavLink
                                                id={id}
                                                to={link}
                                                className="dashboard-link"
                                            >{name}</NavLink>}
                                    </li>
                                );
                            })}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>

              <div
                  className={collapse_at_sm ? 'pos-f-t visible-xxs visible-xs visible-sm' : 'pos-f-t visible-xxs visible-xs'}
                  onMouseLeave={this.triggerClose}>
                <nav className="navbar navbar-xs navbar-dark-xs">
                  <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarToggleExternalContent"
                      aria-controls="navbarToggleExternalContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={this.toggleNavState}
                  >
                    {this.props.menuItem}
                      <span className="toggler-plus">
                      {this.state.open
                          ? <i className="fa fa-times"/> : <i className="fa fa-plus"/>}
                    </span>
                  </button>
                </nav>
                <div className="collapse" id="navbarToggleExternalContent"
                     onMouseLeave={this.triggerClose}>
                  <ul className="nav navbar-nav navbar-xs nav-menu" style={{ fontSize: '16px' }}>
                    {items.map(({ sub = [], link, name, ...item }) => {
                        let id = undefined;

                        if (item.id) {
                            id = `mini-account-nav-${item.id}`;
                        }

                        return (
                            <li
                                id={`nav-option-${name.toLowerCase()
                                    .replace(' ', '-')}`}
                                className={this.props.items[0].name == name ? 'dropdown dashboard-dropdown' : ''}
                                key={name}
                            >
                                {sub.length > 0
                                    ? (
                                        <>
                                            <a className="dropdown-toggle dashboard-link-xs dashboard-menu-top"
                                               data-toggle="dropdown" role="button"
                                               aria-haspopup="true"
                                               aria-expanded="false"
                                               id={id}
                                            >
                                                {name}
                                                {' '}
                                                <span
                                                    className="fa fa-chevron-down dashboard-icon-xs"/>
                                            </a>
                                            <ul className="dropdown-menu dashboard-dropdown-menu-xs">
                                                {sub.map(({ name: subName, id: subId, link: subLink }) => subName && (
                                                    <li className="sub" key={subName}>
                                                        <NavLink
                                                            to={subLink}
                                                            className="dashboard-link-xs "
                                                            data-toggle="collapse"
                                                            data-target="#navbarToggleExternalContent"
                                                            onClick={this.toggleNavState}
                                                            id={`${id || 'child'}-${subId}`}
                                                        >
                                                            {subName}
                                                        </NavLink>
                                                    </li>
                                                ))}

                                            </ul>
                                        </>
                                    )
                                    : (
                                        <NavLink
                                            to={link}
                                            id={id}
                                            className="dashboard-link-xs dashboard-menu-top"
                                            data-toggle="collapse"
                                            data-target="#navbarToggleExternalContent"
                                            onClick={this.toggleNavState}
                                        >
                                            {name}
                                        </NavLink>
                                    )}
                            </li>
                        );
                    })}
                  </ul>
                </div>

              </div>
            </div>

                {this.props.noTitle ||
                <UserPageTitle fm_dashboard={get(this.props, 'fm_dashboard', false)}/>}
          </span>

        );
    }
}

const mapStateToProps = ({ page }) => ({
    menuEnum: page.data.dashboard_menu_enum,
    menuItem: page.data.dashboard_menu_item,
    containerClass: page.data.containerClass,
    windowWidth: page.windowWidth,
});

export default connect(mapStateToProps, { setPage })(UserSubNavigation);
