import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() || {};

const {
    REACT_APP_API_URL,
    REACT_APP_API_PREPEND,
    REACT_APP_API_KEY
} = publicRuntimeConfig || process.env;

export const environment = {
    authEndPoint: '',
    apiEndPoint: `${REACT_APP_API_URL}/${REACT_APP_API_PREPEND}/`,
    apiKey: REACT_APP_API_KEY
};
