import {
    GET_MUST_KNOW_NOTIFICATION,
    GET_MUST_KNOW_NOTIFICATION_FAIL,
    GET_MUST_KNOW_NOTIFICATION_SUCCESS,
    GET_MUST_KNOW_NOTIFICATIONS,
    GET_MUST_KNOW_NOTIFICATIONS_FAIL,
    GET_MUST_KNOW_NOTIFICATIONS_SUCCESS
} from "actions/NotificationTypes";

const INITIAL_STATE = {
    loading: false,
    error: null,
    notification: {
        id: 1,
        type_id: 1,
        subject: 'CubeSmart #6271',
        excerpt: 'Dresser, mattress, box springs, clothing, lamp, toys, bags and totes much more!',
        body: "Unit must be paid for at facility unit is located only<br/><br/>We do not provide dumpsters, or any assistance, you must remove everything offsite yourself<br/><br/>Unit must be completely empty and broom swept, cleaning deposit will be refunded if conditions are met and after employee inspection<br/><br/>If more time is needed to vacate unit, a standard lease agreement may be signed",
        hex_value: 'E47438'
    },
    notifications: [
        // {
        //     'must_know_id': '',
        //     'type_id': '1',
        //     'subject': 'CubeSmart #6271',
        //     'excerpt': 'Dresser, mattress, box springs, clothing, lamp, toys, bags and totes much more!',
        //     'body': "Unit must be paid for at facility unit is located only<br/><br/>We do not provide dumpsters, or any assistance, you must remove everything offsite yourself<br/><br/>Unit must be completely empty and broom swept, cleaning deposit will be refunded if conditions are met and after employee inspection<br/><br/>If more time is needed to vacate unit, a standard lease agreement may be signed",
        //     'hex_value': 'E47438'
        // }
    ],
};


export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case GET_MUST_KNOW_NOTIFICATION:
            return {...state, loading:true, error: null};
        case GET_MUST_KNOW_NOTIFICATION_SUCCESS:
            return { ...state, notification: action.payload, loading: false };
        case GET_MUST_KNOW_NOTIFICATION_FAIL:
            return { ...state, error: "Failed to retrieve notification.", loading: false };
        case GET_MUST_KNOW_NOTIFICATIONS:
            if (action.clear) {
                return {...state, notifications: [], loading: false};
            }

            return {...state, loading:true, error: null};
        case GET_MUST_KNOW_NOTIFICATIONS_SUCCESS:
            return {...state, notifications: action.payload || [], loading: false};
        case GET_MUST_KNOW_NOTIFICATIONS_FAIL:
            return { ...state, error: "Failed to retrieve notification.", loading: false };
        default:
            return state;
    }
}
