import React, { forwardRef } from 'react'
import { Form } from 'react-bootstrap'
import HelpBlock from '../HelpBlock'
import 'styles/components/BaseSelect.less'

const Select = forwardRef(({
    label,
    name,
    children,
    addBlank = false,
    blankLabel = 'Please Select',
    help = false,
    error,
    isInvalid,
    feedbackClassName,
    frontEnd = true,
    ...props
}, ref) => {
    
    return frontEnd ? (
        <div className={`base-select-group ${error && 'parsley-has-error'}`}>
            <label className="control-label">{label}</label>
            <select className="form-control" name={name} ref={ref} {...props}>
                {addBlank && (<option value="" hidden>{blankLabel}</option>)}
                {children}
            </select>
            <HelpBlock help={help} />
            {error && <p className='parsley-errors-list filled'>{error}</p>}
        </div>
    ) : (
        <Form.Group controlId={`form${name}Dropdown`}>
            <Form.Label>{label}</Form.Label>
            <div style={{ position: 'relative' }}>
                <Form.Control
                    as="select"
                    name={name}
                    ref={ref}
                    isInvalid={error}
                    {...props}
                >
                    {addBlank && (<option value="" hidden>{blankLabel}</option>)}
                    {children}
                </Form.Control>
                <i color="#495057" className={`fa fa-chevron-down select-arrow-icon`} />
            </div>
            <Form.Control.Feedback type="invalid" className={feedbackClassName}>
                {error}
            </Form.Control.Feedback>
            <HelpBlock help={help} />
        </Form.Group>
    )
})

export default Select
