import {GET_ADDRESS_COUNTRIES, GET_ADDRESS_COUNTRIES_FAIL, GET_ADDRESS_COUNTRIES_SUCCESS, SET_SELECTED_STATE, SET_STATES} from "actions/AddressTypes";

import {get, find} from 'lodash';

const INITIAL_STATE = {
    loading: false,
    error: null,

    selected_country: {},
    selected_country_id: {},
    selected_state: {},
    selected_timezone: {},

    countries: [],
    states: [],
    timezones: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_ADDRESS_COUNTRIES:
            return {...state, states_loading: true, error: null, countries: [], timezones: []};
        case GET_ADDRESS_COUNTRIES_SUCCESS:
            const countries = get(action, 'payload.countries', []);

            return {...state, states_loading: false, error: null, countries};
        case GET_ADDRESS_COUNTRIES_FAIL:
            return {...state, states_loading: false, error: get(action,  'payload.error', GET_ADDRESS_COUNTRIES_FAIL)};

        case SET_SELECTED_STATE:
            return {...state, selected_state: action.state};

        case SET_STATES: // country has been selected populate the states from what was selected

            let selected_country = find(state.countries, {country_id: action.country_id});
            let states = get(selected_country, 'states', []);

            action.resolve && action.resolve({states});

            return {...state, states, timezones: get(selected_country, 'timezones', []), selected_country, selected_country_id: action.country_id};

        default:
            return state;
    }
}
