import * as Cookies from 'js-cookie';
import {APP_CONFIG} from "../Config";

/** @class */
class CookieStorage {
    domain = null;
    path = null;
    expires = null;
    secure = null;

    /**
     * Constructs a new CookieStorage object
     * @param {object} data Creation options.
     * @param {string} data.path Cookies path (default: '/')
     * @param {integer} data.expires Cookie expiration (in days, default: 365)
     * @param {boolean} data.secure Cookie secure flag (default: true)
     */
    constructor(data = {}) {
        this.domain = APP_CONFIG.COOKIE_DOMAIN;
        this.path = '/';
        this.expires = 365;
        this.secure = true; //!APP_CONFIG.DEV, // true or false
    }

    /**
     * This is used to set a specific item in storage
     * @param {string} key - the key for the item
     * @param {object} value - the value
     * @returns {string} value that was set
     */
    setItem(key, value) {
        Cookies.set(key, value, {
            path: this.path,
            expires: this.expires,
            domain: this.domain,
            secure: this.secure,
        });

        return this.getItem(key);
    }

    /**
     * This is used to get a specific key from storage
     * @param {string} key - the key for the item
     * This is used to clear the storage
     * @returns {string} the data item
     */
    getItem(key) {
        return Cookies.get(key, {
            path: this.path,
            expires: this.expires,
            domain: this.domain,
            secure: this.secure,
        });
    }

    /**
     * This is used to remove an item from storage
     * @param {string} key - the key being set
     * @returns {string} value - value that was deleted
     */
    removeItem(key) {
        return Cookies.remove(key, {
            path: this.path,
            expires: this.expires,
            domain: this.domain,
            secure: this.secure,
        });
    }

    /**
     * This is used to clear the storage
     */
    clear() {
        const settings = {
            path: this.path,
            expires: this.expires,
            domain: this.domain,
            secure: this.secure,
        };

        const cookies = Cookies.get(undefined, settings);
        const keys = Object.keys(cookies || {});

        keys.forEach(k => {
            if (k === "OptanonConsent" || k === "OptanonAlertBoxClosed") {
                return
            }

            return Cookies.remove(k, settings)
        });
    }
}

export default new CookieStorage({secure: false});
